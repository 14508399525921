import React, { Component } from "react";
import { Box, Button, Typography, styled } from "@material-ui/core";

interface Props {}

interface S {}

export default class MiniFooter extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <FooterBox mx={"25px"} mb={"14px"}>
        <Typography variant="body1">&copy; renotary 2024</Typography>
        <Box display={"flex"} style={{columnGap: "24px"}}>
          <Button target="_blank" href="/Termsandconditions">Terms of service</Button>
          <Button target="_blank" href="/PrivacyPolicy">Privacy policy</Button>
        </Box>
      </FooterBox>
    );
  }
}

const FooterBox = styled(Box)({
    display:"flex",
    alignItems:"center",
    width: "96%",
    justifyContent:"space-between",
    "& .MuiTypography-body1":{
      color: "#64748B",
      fontFamily: "Inter",
      lineHeight: "21px",
      fontSize: "14px",
      fontWeight: 500,
  },
    "& .MuiButton-root": {
      color: "#64748B",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "21px",
      textTransform: "none",
      textDecoration: "underline",
    }
  });