import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  IconButton,
  styled,
  ClickAwayListener,
  Box,
  Tabs,
  Tab, Zoom, Tooltip, Popper, Paper,
  Dialog,
  RadioGroup,
} from "@material-ui/core";
import {
  AddRounded,
  Menu,
  Close,
  MoreVert,
  Link,
  Edit,
} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import RequestModal from "./../../dashboard/src/BookNotaryRequest.web";
import CancelNotaryRequestModal from "../../../components/src/CancelNotaryRequestModal.web";
import Loader from "../../../components/src/Loader.web";
import StyledRadio from "../../../components/src/StyledRadio.web";

import { BiMessageSquareDetail } from "react-icons/bi";
import "date-fns";
import {
  localProfile,
  bellIcon,
  cancelImage,
  crossIcon,
  groupImg} from "./assets";



import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Inter",
    subtitle1: {
      fontFamily: "Inter",
      fontWeight: 600, 
      fontSize: '14px',
      color: '#1E293B',
      padding: '15px 0 10px 0'
    },
  },
});
// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  Header = () => {
    return (
      <Box display={"flex"} alignItems={"center"} mt={"32px"}>
        <StyledIconButton data-testID="toggleButton" onClick={this.openSideBar}>
          {this.state.isSideBarOpen ? (
            <Close style={{ width: "50px", height: "56px" }} />
          ) : (
            <Menu style={{ width: "50px", height: "56px" }} />
          )}
        </StyledIconButton>
        <Box width={"calc(100vw - 74px)"}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mx={"20px"}
          >
            <Box
              display={"flex"}
              height={"48px"}
              alignItems={"center"}
              style={{ columnGap: "8px" }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "32px",
                  letterSpacing: "-0.5%",
                }}
              >
                Requests
              </Typography>
            </Box>
            <Box display={"flex"} style={{ columnGap: "16px" }}>
              {this.isNotaryUser() ? (
                <Button
                  style={{
                    color: "#FFF",
                    backgroundColor: "#012275",
                    height: "48px",
                    padding: "0 16px",
                  }}
                  startIcon={<AddRounded />}
                  data-testID="bookNowBtn"
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      lineHeight: "24px",
                      fontSize: "16px",
                      textTransform:'capitalize'
                    }}
                  >
                    Invite Client
                  </Typography>
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#012275",
                    color: "#FFF",
                    height: "48px",
                    padding: "0 16px",
                    textTransform: "none",
                  }}
                  onClick={() => this.setIsNewRequestOrEditRequestOrInviteClient("new")}
                  startIcon={
                    <AddRounded style={{ height: "24px", width: "24px" }} />
                  }
                  data-testID="bookNowBtn"
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: 700,
                    }}
                  >
                    Book Now
                  </Typography>
                </Button>
              )}
              <Box
                borderRadius={"4px"}
                style={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                  backgroundColor: "#FFF",
                }}
                width={"48px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"48px"}
              >
                <img src={bellIcon} />
              </Box>
              <Box
                borderRadius={"8px"}
                display={"flex"}
                height={"48px"}
                px={"8px"}
                alignItems={"center"}
                style={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                  columnGap: "8px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <img
                  style={{ borderRadius: "50%", backgroundColor: "grey" }}
                  src={
                    this.state.userProfilePic !== null
                      ? this.state.userProfilePic
                      : localProfile
                  }
                  width="32px"
                  height="32px"
                  alt="profile_pic"
                />
                <Typography
                  style={{
                    fontWeight: 600,
                    fontFamily: "Inter",
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}
                  data-testID="userName"
                  variant="body2"
                >
                  {this.state.userName}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  renderFooter = () => {
    return (
      <FooterBox >
        <Typography variant="body1">&copy; {configJSON.fotterText}</Typography>
        <Box className="footerBtnBox">
          <Button target="_blank" href="/Termsandconditions" data-testID="termsBtn">{configJSON.termsService}</Button>
          <Button target="_blank" href="/PrivacyPolicy" data-testID="privacyBtn" >{configJSON.privacyPoilcy}</Button>
        </Box>
      </FooterBox>
    )
  };

  renderOngoingNewRequest = () => {
    return (
      <TabBox>
        <Tabs data-testID="tabBtn" value={this.state.value} onChange={this.handletabItemChange}>
          <Tab label="New Requests" />
          <Tab label="Ongoing Requests" />
        </Tabs>
      </TabBox>
    );
  };

  renderPriorityButtons = () => {
    return (
     <>
                <Button
                  style={{
                    backgroundColor: "#F1F5F9",
                    borderRadius: "22px",
                    color: "#011342",
                    fontSize: "14px",
                    lineHeight: "17px",
                    textTransform: "none",
                    padding: "8px 12px 8px 12px",
                    width: "fit-content"
                  }}
                  endIcon={
                    <span
                      style={{
                        width: "8px",
                        height: "8px",
                        backgroundColor: "#F59E0B", 
                        borderRadius: "50%", 
                        display: "inline-block",
                      }}
                      />
                    }            
                  data-testID="PriorityBtn"
                >
                Priority
                </Button>

                <Button
                  style={{
                    backgroundColor: "#F1F5F9",
                    borderRadius: "22px",
                    color: "#011342",
                    fontSize: "14px",
                    lineHeight: "17px",
                    textTransform: "none",
                    padding: "8px 12px 8px 12px",
                    width: "fit-content"
                  }}
                  endIcon={
                    <span
                      style={{
                        alignContent:"center",
                        width: "8px",
                        height: "8px",
                        backgroundColor: "#34D399",
                        borderRadius: "50%", 
                        display: "inline-block",
                      }}
                      />
                    }            
                  data-testID="StandardBtn"
                >
                Standard
                </Button>
                <Button
                  style={{
                    backgroundColor: "#F1F5F9",
                    borderRadius: "22px",
                    color: "#011342",
                    fontSize: "14px",
                    lineHeight: "17px",
                    textTransform: "none",
                    padding: "8px 12px 8px 12px",
                    width: "fit-content"
                  }}
                  endIcon={
                    <span
                      style={{
                        width: "8px",
                        height: "8px",
                        backgroundColor: "#F87171", 
                        borderRadius: "50%", 
                        display: "inline-block",
                      }}
                      />
                    }            
                  data-testID="SuperPriorityBtn"
                >
                Super Priority
                </Button>

                </>   
    )      
  }

  renderSearchBar = () => {
    return (
      <div style={{ position: 'relative', width: '300px' }}>
        <SearchIcon
          style={{
            position: 'absolute',
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#011342',
          }}
        />
        <input
          type="text"
          placeholder="Search Order ID"
          style={{
            width: '100%',
            height: '40px',
            borderRadius: '5px',
            backgroundColor: '#FFF',
            border: '1px solid #D1FAE5',
            padding: '8px 12px 8px 36px',
            fontSize: '14px',
            boxSizing: 'border-box',
          }}
          data-testID="SearchOrderIdInput"
          value={this.state.orderId}
          onChange={this.handleSearchChange}
        />
      </div>
    );
  };

  renderFilterIcon = () => {
    return (
      <ThemeProvider theme={theme}>
        <Box
          borderRadius={"4px"}
          style={{
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            backgroundColor: "#F5F9FF",
          }}
          width={"40px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"40px"}
        >
          <FilterListIcon onClick={this.handlePopup} data-test-id="filter-popup-box" />
          <Dialog
            open={this.state.handleFilterPopup}
            onClose={this.handlePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"


          >
            <Box style={webStyle.popUpStyle} >
              <Box sx={webStyle.popUpHeader}>
                <Box style={{ fontWeight: 500, fontSize: '18px', color: '#012275' }}>
                  Filter
                </Box>
                <Box onClick={this.handlePopup}>
                  <img src={crossIcon} alt="" height='44px' width='44px' />
                </Box>
              </Box>
              <Box style={{ width: '100%', padding: '0 0 0 10px ' }}>
                <Typography variant="subtitle1">Urgency Level</Typography>
                <Box style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }} >
                  <Button
                    style={{
                      ...webStyle.urgencyButtonStyle,
                      border: this.state.priority === 'Standard' ? '1px solid #012275' : ''
                    }}
                    data-testID="simulate-buttons"
                    onClick={() => this.handleUrgencyLevel('Standard')}
                    endIcon={
                      <span
                        style={{
                          alignContent: "center",
                          width: "8px",
                          height: "8px",
                          backgroundColor: "#34D399",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                    }
                  >
                    Standard
                  </Button>

                  <Button
                    style={{
                      ...webStyle.urgencyButtonStyle,
                      border: this.state.priority === 'Priority' ? '1px solid #012275' : ''
                    }}
                    onClick={() => this.handleUrgencyLevel('Priority')}
                    endIcon={
                      <span
                        style={{
                          width: "8px",
                          height: "8px",
                          backgroundColor: "#F59E0B",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                    }
                    data-testID="PriorityBtn"
                  >
                    Priority
                  </Button>

                  <Button
                    style={{
                      ...webStyle.urgencyButtonStyle,
                      border: this.state.priority === 'High Priority' ? '1px solid #012275' : ''
                    }}
                    onClick={() => this.handleUrgencyLevel('High Priority')}
                    endIcon={
                      <span
                        style={{
                          width: "8px",
                          height: "8px",
                          backgroundColor: "#F87171",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                    }
                    data-testID="SuperPriorityBtn"
                  >
                    High Priority
                  </Button>
                </Box>
              </Box>
              <Box style={{ width: '100%', padding: '0 0 0 10px ' }}>
                <Typography variant="subtitle1">Request Status</Typography>
                <Box style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }} >
                  {this.state.requestButtons.map((buttons, index) => (
                    <Button
                      key={buttons}
                      style={{
                        ...webStyle.urgencyButtonStyle,
                        border: index === this.state.handleRequestButton ? '1px solid #012275' : ''
                      }}
                      onClick={() => this.handleRequestButtons(index)}
                      data-testID="request-status-buttons"
                    >
                      {buttons}
                    </Button>
                  ))}
                </Box>
              </Box>
              <Box style={{ width: '%', padding: '10px 0 0 10px ' }}>
                <Typography variant="subtitle1">
                  Type of Notary Service <span style={{ color: '#F87171' }} >*</span>
                </Typography>
                <select
                  placeholder='Type of Notary Service'
                  value={this.state.notary ? this.state.notary : ""}
                  name="Notary service type"
                  data-testID="notary-service-type"
                  onChange={this.handleNotaryChange}
                  style={webStyle.selectField}>
                  <option value="" disabled>Notary service type </option>
                  {this.state.notaryType?.length > 0 && this.state.notaryType?.map((item) => <option key={item.id} value={item.id}>{item?.attributes?.service_name}</option>)}
                </select>
                <Box style={{ paddingTop: '5px' }}>
                  <span style={{ color: '#011342', fontSize: '12px', fontFamily: 'Inter' }}>Select the options that best describes your notary services</span>
                </Box>
              </Box>
              <Box style={{ padding: '10px 0 0 10px ' }}>
                <Typography variant="subtitle1">Date <span style={{ color: '#F87171' }}>*</span></Typography>
                <RadioGroup aria-label="gender" data-testID="date-type-button"
                  name="date" value={this.state.dateType} onChange={this.handleDateTypeChange}>
                  <Box style={{ display: 'flex', paddingLeft: '10px' }}>
                    <StyledRadio label="Request Date" value="Request Date" />
                    <StyledRadio label="Completion Date" value="Completion Date" />

                  </Box>
                </RadioGroup>
                <Box style={webStyle.dateInput}>
                  <div style={{ position: 'absolute', width: '200px', top: 9, left: 15, background: 'white' }}>{this.state.presentDate}</div>
                  <input type="date" data-testID="get-new-date" value={this.state.presentDate} onChange={this.handleDate} style={{ width: '100%', outline: 'none', border: 'none', fontWeight: 700 }} />
                </Box>

                <Box style={{ paddingTop: '5px' }}>
                  <span style={{ color: '#011342', fontSize: '12px' }}>Select Date Range</span>
                </Box>
              </Box>

              <Box style={{ paddingTop: '40px', display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  onClick={this.handleReset}
                  style={{
                    ...webStyle.buttonStyle,
                    backgroundColor: '#FFF',
                    color: '#000A34',
                    border: '1px solid #000A34'
                  }}>Reset</Button>
                <Button
                  variant="contained"
                  style={{
                    ...webStyle.buttonStyle,
                    backgroundColor: this.state.activeButton ? '#012275' : '#CCD3E3',
                    color: 'white'
                  }}
                >
                  Apply Filter
                </Button>
              </Box>


            </Box>
          </Dialog>
        </Box>
      </ThemeProvider>
    )
  }

    renderEmpty = () => {
      let requestMessage;
      if (this.state.roleID === 1) {
        requestMessage = configJSON.noRequest;
      } else if (this.state.roleID === 2 && this.state.value !== 1) {
        requestMessage = configJSON.noNotaryRequest;
      } else {
       requestMessage = configJSON.noOngoingRequest;
      }
  
      let content;
      if (this.state.roleID === 1) {
        content = (
          <>
            {configJSON.textEmpty} <span data-testID="bookNowText">‘{configJSON.bookNow}’</span>.
          </>
        );
      } else if (this.state.roleID === 2 && this.state.value !== 1) {
        content = configJSON.noNotaryRequestforNotaryUser;
      } else {
        content = configJSON.noOngoingforNotaryUser;
      }
      return (
        <EmptyBox>
          <Box className="imgBox">
            <img src={groupImg} alt="" width={"100%"} height="100%" data-testID="emptyImage"/>
          </Box>
          <Box className="txtBox">
            <Typography variant="body1" align="center" data-testID="requestMessage">
              {requestMessage} </Typography>
            <Typography variant="body2" align="center" data-testID="contentMessage">
              {content}
            </Typography>
          </Box>
          {this.state.roleID === 1 && <Button className="bookBtn"  data-testID="bookNowBtn" onClick={() => this.setIsNewRequestOrEditRequestOrInviteClient("new")}>
            <AddIcon className="addIcon" /> {configJSON.bookNow}
          </Button>}
        </EmptyBox>
      );
    };

    renderRequestTable = () => {
      const { isActionBoxActive, actionBoxIndex } = this.state;
      return (
        <MainBox
          style={
            this.state.roleID === 1
              ? { height: "100%" }
              : { height: "100%", marginTop: "5px" }
          }
          onScroll={this.handleScroll}
        >
          <StyledTable>
            <thead style={{ position: "sticky", top: "0px", zIndex: 1 }}>
              <th className="tableTitle">{configJSON.urgency}</th>
              <th className="tableTitle">{configJSON.orderID}</th>
              <th className="tableTitle">{configJSON.notaryService}</th>
              <th className="tableTitle">{configJSON.method}</th>
              <th className="tableTitle">{configJSON.requestDate}</th>
              <th className="tableTitle">{configJSON.status}</th>
              <th className="tableTitle">{configJSON.action}</th>
            </thead>
            <tbody>
              {this.state.rows?.map((rowsData, index) => (
                <tr key={index} className="dataRow">
                  <td
                    align="center"
                    className={this.getUrgencyClass(rowsData.attributes.priority)}
                  >
                    {rowsData.attributes.priority}
                  </td>
                  <td align="center">{rowsData.id}</td>
                  <td align="center">
                    {rowsData.attributes.notary_service_name}
                  </td>
                  <td align="center">
                    <Box className="methodBox">
                      <Typography className="methodTxt">
                        {this.getNotarisationMethod(
                          rowsData.attributes.notarisation_method_id
                        )}
                      </Typography>
                      <Tooltip 
                        arrow
                        data-testID="tooltip"
                        title="Popper has the title"
                        PopperComponent={(popperProps) => (
                          <Popper {...popperProps} placement="bottom">
                            <Paper style={{ backgroundColor: 'white', padding: '8px', maxWidth: "400px", boxShadow: '0px 2px 4px rgba(0,0,0,0.2)' }}>
                              <Typography align="center" style={{fontSize: "12px", fontFamily: "Inter"}}>{this.findToolTiptext(rowsData.attributes.notarisation_method_id)}</Typography>
                            </Paper>
                            <div style={{
                              position: 'absolute',
                              bottom: '100%',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              width: 0,
                              height: 0,
                              borderLeft: '8px solid transparent',
                              borderRight: '8px solid transparent',
                              borderBottom: '8px solid white',
                            }} />
                          </Popper>
                        )}
                        TransitionComponent={Zoom}
                        >
                        <IconButton className="infoIconBtn">
                          <InfoOutlinedIcon className="infoIcon"/>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </td>
                  <td align="center">
                    <span
                      style={{
                        color: this.findDateColor(rowsData),
                      }}
                    >
                      {this.dateReturn(rowsData.attributes.date)}
                    </span>
                  </td>
                  <td align="center">
                    <Box
                      className={`${this.getStatusClass(
                        rowsData.attributes.status.toUpperCase()
                      )} statusBox`}
                    >
                      <Typography variant="body1">
                        {rowsData.attributes.status.toUpperCase()}
                      </Typography>
                    </Box>
                  </td>
                  <td align="center" className="viewBtn">
                    <IconButton
                      data-testID="threeDots"
                      onClick={() => this.handleActionBoxOpen(index)}
                    >
                      <MoreVert className="moreIcon" />
                    </IconButton>
                    {isActionBoxActive && actionBoxIndex === index && (
                        <ClickAwayListener
                          onClickAway={this.handleActionBoxClose}
                        >
                          <ActionBox>
                            <Button
                              data-testID="getNotaryTestId"
                              onClick={() =>
                                this.navigateToRequestDetails(rowsData.id)
                              }
                              startIcon={
                                <VisibilityOutlinedIcon className="eyeIcon" />
                              }
                              className="menuBtn"
                            >
                              <Typography className="viewText">View</Typography>
                            </Button>
                            {this.isEditActionButtonShown(rowsData) && <Button
                              onClick={() =>
                                this.setIsNewRequestOrEditRequestOrInviteClient(
                                  "edit",
                                  rowsData.id
                                )
                              }
                              startIcon={<EditOutlinedIcon className="eyeIcon" />}
                              className="menuBtn"
                              data-testID="editRequestButton"
                            >
                              <Typography className="viewText">Edit</Typography>
                            </Button>}
                            {this.isMeetingActionButtonShown(rowsData) && <Button
                            onClick={() =>
                              this.navigateToZoomMeeting(rowsData.id)
                            }
                              startIcon={<Link className="eyeIcon" />}
                              className="menuBtn"
                              data-testID="meetLinkButton"
                            >
                              <Typography className="viewText">
                                Meeting link
                              </Typography>
                            </Button>}
                            {this.isMessageActionButtonShown(rowsData) && <Button
                              startIcon={
                                <BiMessageSquareDetail className="eyeIcon" />
                              }
                              className="menuBtn"
                            >
                              <Typography className="viewText">Message</Typography>
                            </Button>}
                            {this.isSubmitQuoteActionButtonShown(rowsData) && <Button
                              onClick={() =>this.navigateToRequestDetails(rowsData.id, true)}
                              startIcon={<Edit className="eyeIcon" />}
                              className="menuBtn"
                            >
                              <Typography className="viewText">Submit Quote</Typography>
                            </Button>}
                            {this.isWithdrawQuoteActionButtonshown(rowsData) && <Button
                              onClick={() =>
                                this.navigateToRequestDetails(rowsData.id)
                              }
                              startIcon={<Close className="eyeIcon" />}
                              className="menuBtn"
                            >
                              <Typography className="viewText">
                                Withdraw Quote
                              </Typography>
                            </Button>}
                            {this.isCancelActionButtonShown(rowsData) && <Button
                              data-testID="cancelNotaryRequestButton"
                              onClick={() =>
                                this.openCancelRequestModal(
                                  rowsData.id,
                                  rowsData.attributes.status
                                )
                              }
                              startIcon={<Close className="eyeIcon" />}
                              className="menuBtn"
                            >
                              <Typography className="viewText">Cancel</Typography>
                            </Button>}
                          </ActionBox>
                        </ClickAwayListener>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </MainBox>
      );
    }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div style={{ overflow: "hidden" }}>
        <Box display={"flex"} className="test1">
          <DesktopDrawerBox>
            <NavigationMenu navigation={this.props.navigation} id={""} />
          </DesktopDrawerBox>
          <MobileTabletDrawerBox>
            {this.state.isSideBarOpen && (
              <NavigationMenu navigation={this.props.navigation} id={""} />
            )}
          </MobileTabletDrawerBox>
          <MainBox
            height={"100vh"}
            overflow={"auto"}
            width={this.findMainBoxWidth()}
            style={{ backgroundColor: "#F9F9F9" , overflow: "hidden"}}
          >
            {this.Header()}
            <MainBox
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "15px 12px",
                  overflow: "hidden",
                }}
              >
                <div style={{ gap: "8px", display: "flex" }}>
                {this.conditionOne() && this.renderOngoingNewRequest()}
                  {this.renderPriorityButtons()}
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  {this.renderSearchBar()}
                  {this.renderFilterIcon()}
                </div>
              </MainBox>
              <>
                {this.state.rows.length !== 0
                   ? this.renderRequestTable()
                   : this.renderEmpty()
                  }
              </>
            {this.renderFooter()}
          </MainBox>
        </Box>

        <>
          <Loader loading={this.state.loader} />
          <RequestSectionBox>
            <CancelNotaryRequestModal
              data-testID="cancelNotaryModal"
              text="Are you sure you want to cancel this order?"
              subText={this.state.cancelNotaryRequestStatus.toLowerCase() !== "pending" ? `Cancellation charges will be applied of ${this.state.cancellationCharges}` : undefined}
              cancelImage={cancelImage}
              cancelReqModal={this.state.cancelNotaryRequestModal}
              handleYesButtonClick={this.cancelNotaryrequest}
              handleNoButtonClick={this.cancelNotaryRequestNoButtonClick}
            />
            <RequestModal
              navigation={undefined}
              id={""}
              isOpen={this.state.modalOpen}
              closeModal={this.closeModal}
              data-testID="modalOpen"
              allRequestAPI={this.allRequestAPI}
              serviceData={this.state.serviceData}
              cancelReqModal={this.state.cancelReqModal}
              yesButtonClick={this.yesButtonClick}
              noButtonClick={this.noButtonClick}
              setLoader={this.setLoader}
              setModal={this.setModal}
              editRequest={this.state.editRequest}
              isNewRequestOrEditRequestOrInviteClient={this.state.isNewRequestOrEditRequestOrInviteClient}
            />
          </RequestSectionBox>
        </>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledIconButton = styled(IconButton)({
  "@media (min-width: 1025px)": {
    display: "none",
  },
});

const EmptyBox = styled(Box)({
  padding: "0px 20px",
  display: "flex",
  alignItems: "center",
  height: "100%",
  justifyContent: "center",
  flexDirection: "column",
  gap: "20px",
  "& .imgBox": {
    width: "200px",
    height: "200px"
  },
  "& .txtBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
    "& .MuiTypography-body1": {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "26px",
      fontFamily: "Inter",
      color: "#011342",
    },
    "& .MuiTypography-body2": {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      fontFamily: "Inter",
      color: "#64748B",
      width: "390px",
      "& span": { fontWeight: 600, lineHeight: "22px", color: "#64748B" }
    },
  },
  "& .MuiButton-root": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Inter",
    textTransform: "none",
    color: "white",
    background: "#012275",
    borderRadius: "4px",
    paddingRight: "15px",
    paddingLeft: "15px",
    height: "45px"
  },
  "& .addIcon": {
    paddingRight: "8px"
  },
});
const RequestSectionBox = styled(Box)({
  display: "flex",
  height: "100vh",
  overflowY: "hidden",
  "& .mainSection": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "scroll",
    backgroundColor: "#f9f9f9"
  },
  "& .tabLogo": {
    display: "none",
    width: "100%",
    paddingTop: "20px"
  },
  "& .tabLogoBox": {
    width: "160px",
    height: "50px"
  },
  "@media screen and (max-width:1024px)": {
    "& .sideBar": {
      display: "none"
    },
    "& .sideBarOpen": {
      display: "block"
    },
    "& .tabLogo": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  },

});
const FooterBox = styled(Box)({
  display: "flex",
  padding: "10px 20px",
  alignItems: "center",
  width: "97%",
  justifyContent: "space-between",
  "& .MuiTypography-body1": {
    color: "#64748B",
    fontFamily: "Inter",
    lineHeight: "21px",
    fontSize: "14px",
    fontWeight: 500
  },
  "& .MuiButton-root": {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "21px",
    textTransform: "none",
    padding: "0px 10px",
    textDecoration: "underline"
  }
});

const TabBox = styled(Box)({
  display: "flex",
  "& .MuiTabs-root": {
    minHeight: "30px",
    height: "36px",
    padding: "2px",
    border: "1px solid #012276",
    background: "#f9faf9",
    borderRadius: "80px",
  },
  "& .MuiTab-root": {
    minWidth: "140px",
    width: "160px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    minHeight: "30px",
    height: "36px",
    color: "#011342",
    opacity: 1,
    textTransform: "none",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .Mui-selected": {
    background: "#012275",
    color: "white",
    borderRadius: "35px",
    opacity: 1,
    fontWeight: "600"
  },
});
const DesktopDrawerBox = styled(Box)({
  "@media (max-width: 1024px)": {
    display: "none",
  },
  display: "flex",
});

const MobileTabletDrawerBox = styled(Box)({
  "@media (min-width: 1025px)": {
    display: "none",
  },
});

const MainBox = styled(Box)({
  padding: "0px 10px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  overflowX: "auto",
});
const StyledTable = styled("table")({
  width: "100%",
  borderCollapse: "separate",
  borderSpacing: "0",
  "& .tableTitle": {
    backgroundColor: "#E2E8F0",
    margin: "5px"
  },
  "& .viewBtn": {
    position: "relative"
  },
  "& thead": {
    backgroundColor: "#f9f9f9",
    "& th": {
      height: "54px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      fontFamily: "Inter",
      color: "#011342",
      borderBottom: "2px solid #ddd",
      "&:first-child": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px"
      },
      "&:last-child": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px"
      },
    },
  },
  "& tbody": {
    "& .dataRow": {
      height: "54px",
      "& td": {
        height: "54px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        fontFamily: "Inter",
        color: "#011342",
        padding: "0px 8px",
        borderBottom: "2px solid #ddd",
        "&:first-child": {
          borderTopLeftRadius: "4px",
          borderBottomLeftRadius: "4px"
        },
        "&:last-child": {
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px"
        },
      },
    },
  },
  "& .priority": {
    borderLeft: "6px solid #F59E0B"
  },
  "& .standard": {
    borderLeft: "6px solid #34D399",
    color: "red"
  },
  "& .superPriority": {
    borderLeft: "6px solid #F87171"
  },
  "& .completed": {
    background: "#D1FAE5",
    "& .MuiTypography-body1": {
      color: "#059669"
    }
  },
  "& .cancelled": {
    background: "#FF000038",
    "& .MuiTypography-body1": {
      color: "#FF0000"
    }
  },
  "& .pending": {
    background: "#F0E5FF",
    "& .MuiTypography-body1": {
      color: "#6200EA"
    }
  },
  "& .inprogress": {
    background: "#FEF3C7",
    "& .MuiTypography-body1": {
      color: "#D97706"
    }
  },
  "& .statusBox": {
    height: "30px",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "90px",
    "& .MuiTypography-body1": {
      fontFamily: "Inter",
      fontSize: "10px",
      fontWeight: 700,
      lineHeight: "18px"
    }
  },
  "& .methodBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .methodTxt": {
    color: "#011342",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Inter"
  },
  "& .infoIconBtn": {
    padding: "0px 5px !important"
  },
  "& .infoIcon": {
    height: "18px",
    width: "18px",
    color: "#475569"
  },
});
const ActionBoxNotary = styled(Box)({
  position: "absolute",
  right: 0,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  backgroundColor: "white",
  borderRadius: "4px",
  cursor: "pointer",
  zIndex: 1,
  boxShadow: "0px 2px 8px 0px #00000014",
  "& .visibleIcon": {
    marginRight: "8px",
    "&:hover": {
      color: "#fff"
    }
  },
  "& .menuButton": {
    height: "45px",
    width: "220px",
    display: "flex",
    padding: "25px",
    alignItems: "center",
    gap: "10px",
    justifyContent: "flex-start",
    "& .MuiTypography-body1": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      textTransform: "none",
      textAlign: "left"
    },
    "&:hover": {
      backgroundColor: "#0131A8",
      color: "#fff !important"
    }
  },
});

const ActionBox = styled(Box)({
  right: 0,
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "white",
  cursor: "pointer",
  borderRadius: "4px",
  zIndex: 1,
  boxShadow: "0px 2px 8px 0px #00000014",
  "& .eyeIcon": {
    marginRight: "8px",
    "&:hover": {
      color: "#fff"
    }
  },
  "& .menuBtn":{
    minWidth:"200px",
    height:"40px",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    gap: "10px",
    justifyContent: "flex-start",
    "& .MuiTypography-body1": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      textAlign: "left",
      textTransform: "none"
    },
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#0131A8"
    }
  },
});
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  topWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tableTitleStyle: {
    alignSelf: "flex-start",
    marginTop: 20,
  },
  tableBtnStyle: {
    marginRight: 10,
  },
  inputStyle: {
    marginTop: 15,
    width: 350,
    maxWidth: "100%",
  },
  popUpStyle: {
    // width: '600px',
    padding: '24px 20px',
    boxSizing: 'border-box' as 'border-box',
  },
  popUpHeader: {
    width: '100%',
    borderBottom: '1px solid #CBD5E1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px'
  },
  resetButton: {
    width: '180px',
    borderRadius: '10px',
    fontWeight: 700,
    color: '#012275',
    border: '1px solid #012275'
  },
  urgencyButtonStyle: {
    backgroundColor: "#E2E8F0",
    borderRadius: "22px",
    color: "#011342",
    fontSize: "14px",
    height: "33px",
    textTransform: "none" as 'none',
    padding: "8px 12px 8px 12px",
    width: "fit-content",
    fontFamily: 'Inter'
  },
  dateInput: {
    border: '1px solid black',
    padding: '10px 12px 10px 12px',
    borderRadius: '8px',
    // width: '100%',
    position: 'relative' as 'relative',
    display: 'flex',
    justifyContent: 'space-between'
  },
  selectField: {
    width: '100%',
    border: '1px solid',
    backgroundColor: 'transparent',
    outline: 'none',
    padding: '10px 12px 10px 12px',
    borderRadius: '8px',
    fontSize: '16px'
  },
  buttonStyle: {
    width: '160px',
    borderRadius: '8px',
    fontWeight: 700,
    fontFamily: 'Inter',
    textTransform: 'capitalize' as 'capitalize',
    height: '44px'
  },
};
// Customizable Area End
