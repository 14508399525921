import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React, { createRef } from "react";
import Slider from "react-slick";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";

interface NotaryRequest {
  id: string;
  type: string;
  attributes: Attributes;
  relationships: Relationships;
}

interface Attributes {
  status: string;
  notary_id: number | null;
  notarisation_method_id: number;
  notary_service_name: string;
  priority: string;
  notary_service_type: number;
  jurisdiction_id: number;
  date: string;
  notes: string;
  notarised_document: number;
  timing_slot: string;
  file_documents: (FileDocumentsEntity)[];
  juridiction: Juridiction;
  notarisation_method: NotarisationMethod;
  quote_statuses_id: number;
}

interface Juridiction {
  id: number;
  jurisdiction: string;
  created_at: string;
  updated_at: string;
}

interface NotarisationMethod {
  id: number;
  notarisation_method: string;
  created_at: string;
  updated_at: string;
}

interface FileDocumentsEntity {
  doc_type: string;
  doc_name: string;
  doc_id: number;
  doc_base_64: string;
  doc_size: number;
  signatory_count: number;
  doc_file_url: string;
  recipients?: (RecipientsEntity)[] | null;
}

interface RecipientsEntity {
  created_at: string;
  name: string;
  id: number;
  file_document_id: number;
  updated_at: string;
  is_signatory: boolean;
  email: string;
  is_notary: boolean;
  signed: boolean;
}

interface Relationships {
  notarisation_method: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  jurisdiction: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  account: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  notary: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
}

interface JurisdictionOrNotaryOrNotarisationMethodOrAccount {
  data: Data | null;
}

interface Data {
  type: string;
  id: string;
}

interface ApiCallInterface {
  contentType?:string;
  method?:string;
  endPoint?:string;
  body?:object;
}

interface ValidResponseType {
  message: object;
  data: object;
  errors:string;
  status: number;
}

interface DataofService {
  id: string;
  type: string;
  attributes: {
    id: number;
    service_icon?: {
      url: string;
    };
    service_name: string;
    service_description: string;
    is_selected: boolean;
  }
}

interface CompleteProfileData {
  is_phone_number: boolean;
  is_address: boolean;
  is_notary_service: boolean;
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  rows: NotaryRequest[];
  isActionBoxActive: boolean;
  actionBoxIndex: number | null;
  allRequest: number;
  modalOpen: boolean;
  progressRequest: number;
  completeCount: number;
  serviceData: DataofService[];
  currentPage: number;
  pageSize: number;
  lastPage: number;
  isSideBarOpen:boolean;
  activeSteps: number;
  ageValue: string;
  userName: string;
  userProfilePic: string;
  cancelReqModal: boolean;
  expandedAccordion: string;
  selectedDateOnSave: string| Date | null;
  checkboxOne1: boolean;
  isFiles: boolean;
  checkboxTwo2: boolean;
  roleID: number;
  loader: boolean;
  isName: boolean;
  isEmail: boolean;
  termOne: boolean;
  termTwo: boolean;
  isEmailInvalid : boolean;
  outgoingCount: number;
  value: number;
  ongoingRequest: NotaryRequest[];
  newRequest: NotaryRequest[];
  cancelNotaryRequestModal: boolean;
  cancelNotaryRequestStatus: string;
  cancellationCharges: number;
  currentCancelRequest: string;
  isNewRequestOrEditRequestOrInviteClient: string;
  editRequest: NotaryRequest | undefined;
  phoneNumber: string;
  completeProfileData: CompleteProfileData | null;
  isUserActive: boolean
  userType: string;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAllRequestApiCallId: string = "";
  getAllNotaryRequestsCallId: string = "";
  getServicesApiCallId: string = "";
  getProfileApiCallID: string = "";
  createNewNotaryApiCallID: string = "";
  getCancellationChargesApiCallId: string = "";
  putCancelNotaryRequestCallId: string = "";
  completeProfileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      errorMsg: "",
      token: "",
      loading: false,
      allRequest: 0,
      progressRequest: 0,
      completeCount: 0,
      modalOpen: false,
      selectedDateOnSave: "",
      userName: "",
      userProfilePic: "",
      loader: false,
      cancelReqModal: false,
      serviceData: [],
      currentPage: 0,
      pageSize: 3,
      lastPage: 2,
      rows:[],
      roleID: 0,
      ongoingRequest: [],
      newRequest: [],
      value: 0,
      isActionBoxActive: false,
      actionBoxIndex: null,
      isSideBarOpen:false,
      activeSteps: 0,
      ageValue: "",
      expandedAccordion: "students",
      checkboxOne1: false,
      isFiles: false,
      checkboxTwo2: false,
      termOne: false,
      termTwo: false,
      isEmailInvalid : false,
      isName: false,
      isEmail: false,
      outgoingCount: 0,
      cancelNotaryRequestModal: false,
      cancelNotaryRequestStatus: "",
      cancellationCharges: 0,
      currentCancelRequest: "",
      isNewRequestOrEditRequestOrInviteClient: "new",
      editRequest: undefined,
      phoneNumber: "",
      completeProfileData: null,
      isUserActive: false,
      userType:''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // this.getDashboardData();
    // Customizable Area Start
    this.setState({ loader: true});
    this.getToken();
    this.getProfile();
    this.allRequestCount();
    this.allRequestAPI();
    this.getServicesAPI();    
    this.completeProfileInfo();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {
      "Content-Type": webConfigJSON.dashboarContentType,
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(this.isValidResponse(responseJson)) {
        switch(apiRequestCallId) {
          case this.getAllRequestApiCallId:
            this.setState({
              allRequest: responseJson.all_request_count,
              progressRequest: responseJson.in_progress_request_count,
              completeCount: this.findCompleteCount(responseJson.user_completed_request_count, responseJson.notary_completed_request_count),
            });
            break;
          case this.getAllNotaryRequestsCallId:
            this.setState(
              {
                rows:
                  this.state.roleID === 1
                    ? responseJson?.end_user_notary_requests?.data
                    : responseJson?.new_notary_requests?.data,
                ongoingRequest: responseJson?.notary_ongoing_requests?.data,
                newRequest: responseJson?.new_notary_requests?.data,
                loader: false,
              },
              () => this.allRequestCount()
            );
            break;
          case this.getServicesApiCallId:
            this.setState({ serviceData: responseJson.data });
            break;
          case this.getProfileApiCallID:
            this.setState({
              userName: responseJson.data.attributes.full_name,
              roleID: responseJson.data.attributes.role_id,
              userProfilePic: responseJson.data.attributes.photo?.url,
              phoneNumber: responseJson.data.attributes.full_phone_number,
              userType: responseJson.data.attributes.user_type,
            });
            break;
          case this.getCancellationChargesApiCallId:
            this.setState({
              cancellationCharges: responseJson.cancellation_charges,
              loader: false,
            });
            break;
          case this.putCancelNotaryRequestCallId:
            this.checkIfRequestIsCancelledOrNot(responseJson.message);
            break;
          case this.completeProfileApiCallId:
            this.handleCompleteProfileInfo(responseJson.data);
            break;
        }
      }
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      setStorageData("token",token)
    }
    // Customizable Area End
  }
  // Customizable Area Start
  checkIfRequestIsCancelledOrNot = (message: string) => {
    if (message === "Notary request cancelled successfully.") this.allRequestAPI();
  }
  
  standardizeRequestCounts = (count: number) => count < 10 ? "0" + count.toString() : count;

  findCompleteCount = (endUserCount: number, notaryUserCount: number) => this.isEndUser() ? endUserCount : notaryUserCount;

  navigationBtn = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileBasicBlock");
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    )
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      isEdit: true
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message)
  }

  navigationButton = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "UserNotaryService");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
    this.send(message);
  }

  navigateToRequestDetails (requestId: string, isSubmitQuote: boolean = false) {
    setStorageData("userName", this.state.userName);
    setStorageData("userRoleId", this.state.roleID);
    setStorageData("userProfilePic", this.state.userProfilePic);
    setStorageData("notaryRequestId", requestId);
    setStorageData("isSubmitQuoteOpen", isSubmitQuote);
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "RequestDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
    this.send(message);
  }

  navigateToZoomMeeting = (requestId: string) => {
    setStorageData("notaryRequestId", requestId);
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Cfzoomintegration92");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
    this.send(message);
  }

  setIsNewRequestOrEditRequestOrInviteClient = (value: string, requestId: string = "") => {
    this.setState(
      {
        isNewRequestOrEditRequestOrInviteClient: value,
        editRequest: this.state.rows.find((row) => row.id === requestId),
      },
      () => {this.setModal(true);}
    );
  }

  handletabItemChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue, rows: newValue === 1 ? this.state.ongoingRequest : this.state.newRequest });
  };
  
  cancelNotaryRequestNoButtonClick = () => {
    this.setState({ cancelNotaryRequestModal: false, currentCancelRequest: "", cancellationCharges: 0, cancelNotaryRequestStatus: "" });
  };

  cancelNotaryrequest = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": webConfigJSON.cancelNotaryRequestContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putCancelNotaryRequestCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.cancelNotaryRequestApiEndpointPart1 + this.state.currentCancelRequest + webConfigJSON.cancelNotaryRequestApiEndpointPart2
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.cancelNotaryRequestApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ cancelNotaryRequestModal: false, currentCancelRequest: "", cancellationCharges: 0, cancelNotaryRequestStatus: "" });
  }

  setLoader = (value: boolean) => {
    this.setState({loader: value})
  }

  setModal = (value: boolean) => {
    this.setState({modalOpen: value})
  }

  openCancelRequestModal = (requestId: string, requestStatus: string) => {
    this.setState({cancelNotaryRequestModal: true, cancelNotaryRequestStatus: requestStatus, currentCancelRequest: requestId, loader: true})
    this.getCancellationCharges();
  }

  getCancellationCharges = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": webConfigJSON.getCancellationChargesContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCancellationChargesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getCancellationChargesApiEndpointPart1 + this.state.currentCancelRequest + webConfigJSON.getCancellationChargesApiEndpointPart2
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getCancellationChargesApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //istanbul ignore next 
  disableBackButton() {
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = function() {
      window.history.pushState(null, '', window.location.href);
    };
  }

  sliderRef = createRef<Slider>();

  handleNext = () => {
    if (this.sliderRef.current) {
      this.sliderRef.current.slickNext();
    }
  };

  handlePrev = () => {
    if (this.sliderRef.current) {
      this.sliderRef.current.slickPrev();
    }
  };

  inValidResponses = (responseJson: ValidResponseType) => {
    return responseJson &&  responseJson.errors;
  }
  isValidResponse = (responseJson: ValidResponseType) => {
    if(responseJson.status !== 500){
    return responseJson && !responseJson.errors;
    }
  }

  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };
  
  getUrgencyClass = (urgency: string) => {
    switch (urgency) {
      case "Priority":
        return "priority";
      case "Standard":
        return "standard";
      case "Super Priority":
        return "superPriority";
      default:
        return "";
    }
  };

  getStatusClass = (status: string) => {
    switch (status) {
      case "COMPLETED":
        return "completed";
      case "CANCELLED":
        return "cancelled";
      case "PENDING":
        return "pending";
      case "IN PROGRESS":
        return "inprogress";
      default:
        return "";
    }
  };

  getNotarisationMethod= (idOfMethod: number) => {
    switch(idOfMethod) {
      case 1:
          return "REN";
      case 2:
          return "RIN";
      default:
          return "REIN";
  }
  };

  findToolTiptext = (idOfMethod: number) => {
    switch(idOfMethod) {
      case 1:
          return "Remote Electronic Notarisation (Fully Automated and e-signatures)";
      case 2:
          return "Remote Ink Notarisation (Completely Manual; User and Notary signs it physically)";
      default:
          return "Remote Electronic Ink Notarisation (Partially Automated; User digitally signs it and Notary signs it physically)";
    }
  };

  handleActionBoxOpen = (indexValue: number) => {
    this.setState({ isActionBoxActive: true, actionBoxIndex: indexValue });
  };

  handleActionBoxClose = () => {
    this.setState({ isActionBoxActive: false, actionBoxIndex: null });
  };

  isEndUser = () => this.state.roleID === 1;

  isNotaryUser = () => this.state.roleID === 2;

  isRequestStatusPending = (requestStatus: string) => requestStatus.toLowerCase() === "pending";

  isRequestStatusInProgress = (requestStatus: string) =>
    requestStatus.toLowerCase() === "in progress";

  isEditActionButtonShown = (notaryRequest: NotaryRequest) => this.isEndUser() && this.isRequestStatusPending(notaryRequest.attributes.status);

  isCancelActionButtonShown = (notaryRequest: NotaryRequest) => this.isEndUser() && (this.isRequestStatusPending(notaryRequest.attributes.status) || this.isRequestStatusInProgress(notaryRequest.attributes.status));

  isMessageActionButtonShown = (notaryRequest: NotaryRequest) => this.isRequestStatusInProgress(notaryRequest.attributes.status);

  isMeetingActionButtonShown = (notaryRequest: NotaryRequest) => this.isRequestStatusInProgress(notaryRequest.attributes.status);

  isSubmitQuoteActionButtonShown = (notaryRequest: NotaryRequest) => this.isNotaryUser() && (notaryRequest.attributes.quote_statuses_id === 2 || notaryRequest.attributes.quote_statuses_id === null);

  isWithdrawQuoteActionButtonshown = (notaryRequest: NotaryRequest) => this.isNotaryUser() && notaryRequest.attributes.quote_statuses_id === 1;

  closeModal = () => {
    this.setModal(!this.state.modalOpen);
    this.setState({ cancelReqModal: true });
  };

  noButtonClick = () => {
    this.setModal(!this.state.modalOpen);
    this.setState({ cancelReqModal: false });
  };

  yesButtonClick = () => {
    this.setState({cancelReqModal: false})
  };

  apiCall = async (apiData: ApiCallInterface) => {
   let token = await getStorageData("token");
    const { contentType, method, endPoint, body } = apiData;
    const header = {
        "Content-Type": contentType,
         token: token
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    body &&
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
};

  allRequestCount = async () => {
    this.getAllRequestApiCallId = await this.apiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.dashboarApiMethodType,
      endPoint: webConfigJSON.allRequestCountAPI
    });
  };

  allRequestAPI = async () => {
    this.getAllNotaryRequestsCallId = await this.apiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.dashboarApiMethodType,
      endPoint: webConfigJSON.getAllNotaryRequestApiEndpoint
    });
  };

  getServicesAPI = async () => {
    this.getServicesApiCallId = await this.apiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.dashboarApiMethodType,
      endPoint: webConfigJSON.allServiceApiEndpoint
    });
  };

  getProfile = async () => {
    this.getProfileApiCallID = await this.apiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.dashboarApiMethodType,
      endPoint: webConfigJSON.getProfileEndPoint
    });
  };

  openSideBar = () => {
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen });
  };

  handleChanges = (event:{ target: { name : string, value: string}}) => {
    this.setState({
      ...this.state,
     ageValue: event.target.value,
    });
  };

  handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean = true
  ) => {
    this.setState({ expandedAccordion: isExpanded ? panel : "" });
  };

  findDateColor = (notaryRequest : NotaryRequest) => {
    const receivedDate = new Date(notaryRequest.attributes.date);
    const dateToday = new Date();
    receivedDate.setHours(0,0,0,0);
    dateToday.setHours(0,0,0,0);
    return receivedDate < dateToday && this.isEndUser() && this.isRequestStatusPending(notaryRequest.attributes.status) ? "red" : "#011342"
  }
  
  dateReturn = (value : string) => {
   let formattedDate =  moment(value).format("DD/MM/yyyy");
   return formattedDate;
  };

  condition = () => {
    return this.state.roleID === 2
  };

  conditionOne = () => {
    return this.isNotaryUser() && this.state.phoneNumber !== "" 
  };

  completeProfileInfo = async () => {
    this.completeProfileApiCallId = await this.apiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: "GET",
      endPoint: "bx_block_profile/complete_profile"
    });
  };

  handleCompleteProfileInfo = (responseJson: CompleteProfileData) => {
    const {is_phone_number, is_address, is_notary_service} = responseJson;
    if(is_phone_number && is_address && is_notary_service){
      this.setState({isUserActive: true});
    }
    this.setState({completeProfileData: responseJson})
  }

  countStatus = () => {
    const hasRequests = this.state.allRequest !== 0;
    const profileData = this.state.completeProfileData;
  
    const hasCompleteProfile = profileData &&
                              profileData?.is_phone_number === true &&
                              profileData?.is_address === true &&
                              profileData?.is_notary_service === true;
    return this.isNotaryUser() && hasRequests && hasCompleteProfile;
  }
  // Customizable Area End
}
