import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import React from "react";
import { Moment } from "moment";
import {
  DataofService,
  IAllServices,
} from "../../catalogue/src/CatalogueController";
import { failureImage, successImage } from "./assets";

export interface IProfile {
  data: IProfileData;
}
export interface IProfileData {
  id: string;
  type: string;
  attributes: IProfileAttributes;
}
export interface IProfileAttributes {
  id: number;
  first_name?: null;
  last_name?: null;
  full_phone_number: string;
  city?: null;
  post_code?: null;
  country_code?: null;
  phone_number?: null;
  email: string;
  activated: boolean;
  user_type: string;
  user_name?: null;
  platform?: null;
  suspend_until?: null;
  status: string;
  role_id: number;
  full_name: string;
  gender?: null;
  date_of_birth?: null;
  age?: null;
  country?: null;
  address?: null;
  address_line_2?: null;
  contact_name: string;
  company_name: string;
  photo: IProfilePhoto;
}
export interface IProfilePhoto {
  url: string | null;
}

export interface ValidResponseType {
  message: object;
  data: object;
  errors: string;
}

export interface ISubmitQuoteFailureResponse {
  errors: string[];
}

export interface NotaryRequestDetails {
  data: Data;
}
export interface Data {
  id: string;
  type: string;
  attributes: RequestDetailAttributes;
  relationships: RequestDetailRelationships;
}
export interface RequestDetailAttributes {
  status: string;
  notary_id: number | null;
  notary_service_type: number;
  priority?: string | null;
  notarisation_method_id: number;
  jurisdiction_id: number;
  date: string;
  notes: string;
  notarised_document: number;
  timing_slot: string;
  juridiction: Juridiction;
  notarisation_method: NotarisationMethod;
  notary_service_name: string;
  file_documents: FileDocumentsEntity[];
}
export interface Juridiction {
  id: number;
  jurisdiction: string;
  created_at: string;
  updated_at: string;
}
export interface NotarisationMethod {
  id: number;
  notarisation_method: string;
  created_at: string;
  updated_at: string;
}
export interface FileDocumentsEntity {
  doc_id: number;
  doc_type: string;
  doc_size: number;
  doc_base_64: string;
  doc_name: string;
  doc_file_url: string;
  signatory_count: number;
  recipients?: RecipientsEntity[] | null;
}
export interface RecipientsEntity {
  id: number;
  created_at: string;
  updated_at: string;
  file_document_id: number;
  name: string;
  email: string;
  is_signatory?: boolean | null;
}
export interface RequestDetailRelationships {
  jurisdiction: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  notary: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  notarisation_method: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  account: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
}
export interface JurisdictionOrNotaryOrNotarisationMethodOrAccount {
  data: JurisdictionOrNotaryOrNotarisationMethodOrAccountData1;
}
export interface JurisdictionOrNotaryOrNotarisationMethodOrAccountData1 {
  id: string;
  type: string;
}
export interface Notary {
  data?: null;
}

export interface CancellationCharge {
  cancellation_charges: number;
}

export interface CancelNotaryRequest {
  notary_id: number;
  message: string;
}

export interface ISubmitQuoteResponse {
  id: number;
  message: string;
}

export interface IGetQuotesList {
  data?: IQuote[] | null;
}

export interface IQuote {
  id: string;
  type: string;
  attributes: IQuoteAttributes;
}
export interface IQuoteAttributes {
  notary_requests_id: number;
  start_time: string;
  video_call_required: boolean;
  fees: string;
  end_time: string;
  platform_fee_inclusive: boolean;
  message: string;
  notary_id: number;
  created_at: string;
  updated_at: string;
  quote_statuses_id: number;
  quote_status: string;
  notary_fees: string;
  platform_fees: string;
}

interface IAcceptQuote {
  message: string;
}

interface ApiCallInterface {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
}

enum SuccessFailModalType {
  None = "None",
  PaymentSuccess = "PaymentSuccess",
  PaymentFail = "PaymentFail",
  QuoteSubmission = "QuoteSubmission",
}

interface IMeetingCreated {
  start_time: string;
  end_time: string;
  message: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  quotes: IQuote[];
  quoteId: string;
  isPreviewQuoteModalOpen: boolean;
  quotePreviewIndex: number;
  quoteStartTime: string;
  quoteEndTime: string;
  quoteNotaryFees: string;
  quotePlatformFees: string;
  quoteMessage: string;
  modalOpen: boolean;
  serviceData: Array<DataofService>;
  cancelBookNowReqModal: boolean;
  roleID: number;
  userName: string;
  userProfilePic: string | null;
  tabIndex: number;
  areTabsDisabled: boolean;
  status: string;
  notaryDate: Date;
  jurisdiction: string;
  numberOfSignatory: number;
  description: string;
  documents: FileDocumentsEntity[];
  typeOfNotaryService: string;
  notarisationMethod: string;
  notaryRequestId: string;
  isSideBarOpen: boolean;
  cancelReqModal: boolean;
  cancelReqModalTitle: string;
  cancelQuoteId: string;
  cancellationCharges: number;
  timing_slot: string;
  loader: boolean;
  isMakeQuoteModalOpen: boolean;
  isVideoCallChecked: boolean;
  fees: string | null;
  isPlatformFeesChecked: boolean;
  message: string;
  startTime: Date | null;
  endTime: Date | null;
  isSubmitQuoteButtonDisabled: boolean;
  timeErrorText: string;
  feesErrorText: string;
  videoCallError: boolean;
  messageError: boolean;
  isSuccessFailModalOpen: boolean;
  successFailModalType: SuccessFailModalType;
  successFailModalImage: string;
  successFailModalText: string;
  successFailModalTextColor: string | undefined;
  successFailModalSubText: string;
  successFailModalSubText2: string;
  successFailModalButtonText: string;
  isZoomModalOpen: boolean;
  meetingStartTime: string;
  meetingEndTime: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BlockController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getServicesApiCallId: string = "";
  getProfileApiCallId: string = "";
  submitQuoteApiCallId: string = "";
  getNotaryRequestDetailsCallId: string = "";
  getCancellationChargesApiCallId: string = "";
  putCancelNotaryRequestCallId: string = "";
  getQuotesListApiCallId: string = "";
  acceptQuoteApiCallId: string = "";
  withdrawQuoteApiCallId: string = "";
  getMeetingTimeApiCallId: string = "";
  generateMeetingApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      quotes: [],
      quoteId: "",
      quoteStartTime: "09:30 AM",
      isPreviewQuoteModalOpen: false,
      quotePreviewIndex: 0,
      quoteEndTime: "09:30 AM",
      quoteNotaryFees: "",
      quotePlatformFees: "",
      quoteMessage: "",
      serviceData: [],
      modalOpen: false,
      cancelBookNowReqModal: false,
      roleID: 0,
      userName: "",
      userProfilePic: null,
      tabIndex: 0,
      areTabsDisabled: true,
      status: "",
      notaryDate: new Date(),
      jurisdiction: "",
      numberOfSignatory: 0,
      description: "",
      documents: [],
      notarisationMethod: "",
      notaryRequestId: "",
      typeOfNotaryService: "",
      isSideBarOpen: false,
      cancelReqModal: false,
      cancelReqModalTitle: "",
      cancelQuoteId: "",
      cancellationCharges: 0,
      timing_slot: "",
      loader: true,
      isMakeQuoteModalOpen: false,
      isVideoCallChecked: false,
      fees: "",
      isPlatformFeesChecked: false,
      message: "",
      startTime: null,
      endTime: null,
      isSubmitQuoteButtonDisabled: true,
      timeErrorText: "",
      feesErrorText: "",
      videoCallError: false,
      messageError: false,
      isSuccessFailModalOpen: false,
      successFailModalType: SuccessFailModalType.None,
      successFailModalText: "",
      successFailModalTextColor: undefined,
      successFailModalSubText: "",
      successFailModalSubText2: "",
      successFailModalButtonText: "",
      successFailModalImage: "",
      isZoomModalOpen: false,
      meetingStartTime: (new Date()).toISOString(),
      meetingEndTime: (new Date()).toISOString(),
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponse(webResponseJson)) {
        if (webApiRequestCallId === this.getMeetingTimeApiCallId && webResponseJson.zoom_meetings.start_time && webResponseJson.zoom_meetings.end_time)
          this.setState({meetingStartTime: webResponseJson.zoom_meetings.start_time, meetingEndTime: webResponseJson.zoom_meetings.end_time});
        this.responseSuccessCallBack(webApiRequestCallId, webResponseJson);
      } else {
        this.responseFailureCallBack(webApiRequestCallId, webResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const isSubmitQuoteOpen = await getStorageData("isSubmitQuoteOpen")
    removeStorageData("isSubmitQuoteOpen");
    this.setState(
      { 
        notaryRequestId: await getStorageData("notaryRequestId"),
        isMakeQuoteModalOpen: isSubmitQuoteOpen === "true" ? true : false
      },
      this.getNotaryRequestDetails
    );
    this.getProfile();
    this.getServicesAPI();
    const queryParams = new URLSearchParams(window.location.search);
    const isPaymentSuccess = queryParams.get("isSuccess") as string;
    const quoteId = queryParams.get("quoteId") as string;
    this.setState({ quoteId: quoteId });
    if (isPaymentSuccess === "true") {
      this.showPaymentSuccessModal();
      this.acceptQuoteApi(true);
    } else if (isPaymentSuccess === "false") {
      this.showPaymentFailureModal();
      this.acceptQuoteApi(false);
    }
  }

  apiCall = async (apiData: ApiCallInterface) => {
    let token = await getStorageData("token");
    const { contentType, method, endPoint, body } = apiData;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  acceptQuoteApi = async (toAccept: boolean) => {
    const bodyData = {
      is_accepted: toAccept.toString(),
      cs_id: await getStorageData("cs_id"),
    };
    removeStorageData("cs_id");
    this.acceptQuoteApiCallId = await this.apiCall({
      contentType: configJSON.acceptQuoteApiContentType,
      method: configJSON.acceptQuoteApiMethodType,
      endPoint:
        configJSON.acceptQuoteApiEndpoint1 +
        this.state.quoteId +
        configJSON.acceptQuoteApiEndpoint2,
      body: bodyData,
    });
  };

  getMeetingTime = async () => {
    this.getMeetingTimeApiCallId = await this.apiCall({
      contentType: configJSON.getMeetingTimeApiContentType,
      method: configJSON.getMeetingTimeApiMethodType,
      endPoint:
        configJSON.getMeetingTimeApiEndpoint +
        this.state.notaryRequestId
    })
  }

  generateMeeting = async () => {
    const body = {
      notary_request_id: this.state.notaryRequestId,
      quote_id: this.state.quoteId
    }
    this.generateMeetingApiCallId = await this.apiCall({
      contentType: configJSON.generateMeetingApiContentType,
      method: configJSON.generateMeetingApiMethodType,
      endPoint: configJSON.generateMeetingApiEndpoint,
      body: body
    })
  }

  showPaymentSuccessModal = async () => {
    this.setState({
      isSuccessFailModalOpen: true,
      successFailModalType: SuccessFailModalType.PaymentSuccess,
      successFailModalImage: successImage,
      successFailModalText: "Payment Successful",
      successFailModalSubText: `Your Payment towards Order ID: ${this.state.notaryRequestId} has been successfully processed.`,
      successFailModalSubText2:
        "You can review the details of your order below.",
      successFailModalButtonText: "Check status",
    });
  };

  showPaymentFailureModal = () => {
    this.setState({
      isSuccessFailModalOpen: true,
      successFailModalType: SuccessFailModalType.PaymentFail,
      successFailModalImage: failureImage,
      successFailModalText: "Payment Failed",
      successFailModalTextColor: "#FF0000",
      successFailModalSubText: `Unfortunately, your payment attempt for Order ID: ${this.state.notaryRequestId} was not successful.`,
      successFailModalSubText2: "Please attempt the payment again below.",
      successFailModalButtonText: "Retry",
    });
  };

  redirectToPaymentOptions = () => {
    const navigationData = {
      quoteId: this.state.quoteId,
      notaryRequestId: this.state.notaryRequestId,
    };

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PaymentOptions"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.CustomDataMessage),
      navigationData
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  getQuotesList = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.getQuotesListApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuotesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuotesListApiEndPointPart1 +
        this.state.notaryRequestId +
        configJSON.getQuotesListApiEndPointPart2
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getQuotesListApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  previewQuote = (quote: IQuote, quoteIndex: number) => {
    const formattedMessage = quote.attributes.message.replace(/\n/g, "<br />");
    this.setState({
      quoteId: quote.id,
      quotePreviewIndex: quoteIndex,
      quoteStartTime: quote.attributes.start_time,
      quoteEndTime: quote.attributes.end_time,
      quoteNotaryFees: parseFloat(quote.attributes.notary_fees).toFixed(2),
      quotePlatformFees: parseFloat(quote.attributes.platform_fees).toFixed(2),
      quoteMessage: formattedMessage,
      isPreviewQuoteModalOpen: true,
    });
  };

  closePreviewQuote = () => this.setState({ isPreviewQuoteModalOpen: false });

  handleStartTimeChange = (value: Moment | null) => {
    if (value !== null)
      this.setState(
        {
          startTime: value.toDate(),
          timeErrorText: "",
        },
        this.toggleIsSubmitQuoteButtonDisabled
      );
  };

  handleEndTimeChange = (value: Moment | null) => {
    if (value !== null)
      this.setState(
        {
          endTime: value.toDate(),
          timeErrorText: "",
        },
        this.toggleIsSubmitQuoteButtonDisabled
      );
  };

  setLoader = (value: boolean) => this.setState({ loader: value });

  setBookNowModal = (value: boolean) => this.setState({ modalOpen: value });

  closeBookNotaryRequestModal = () => {
    this.setBookNowModal(!this.state.modalOpen);
    this.setState({ cancelBookNowReqModal: true });
  };

  bookNowNoButtonClick = () => {
    this.setBookNowModal(!this.state.modalOpen);
    this.setState({ cancelBookNowReqModal: false });
  };

  bookNowYesButtonClick = () => this.setState({ cancelBookNowReqModal: false });

  getServicesAPI = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.allservicesApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServicesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allServicesApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.allServicesApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProfile = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.getProfileContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getProfileApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  isEndTimeNotGreaterThanStartTimeBy30Minutes = (
    startTime: Date,
    endTime: Date
  ) => {
    if (endTime > startTime) {
      const differenceInMilliseconds = endTime.getTime() - startTime.getTime();
      const minutesto = 30 * 60 * 1000;
      const diff = minutesto - differenceInMilliseconds;
      return diff > 0;
    } else {
      return true;
    }
  };

  toggleIsSubmitQuoteButtonDisabled = () => {
    const { startTime, endTime, fees } = this.state;
    let isAnythingNull = false;
    if (startTime === null) {
      isAnythingNull = true;
    }
    if (endTime === null) {
      isAnythingNull = true;
    }
    if (fees === "") {
      isAnythingNull = true;
    }
    this.setState({ isSubmitQuoteButtonDisabled: isAnythingNull });
  };

  findSubmitButtonBackgroundColor = () =>
    this.state.isSubmitQuoteButtonDisabled ? "grey" : "#012275";

  getStartTimeToSubmitQuote = () => {
    const { startTime, notaryDate } = this.state;
    if (startTime !== null) {
      startTime.setDate(notaryDate.getDate());
      startTime.setMonth(notaryDate.getMonth());
      startTime.setFullYear(notaryDate.getFullYear());
      return startTime.toISOString();
    }
  };

  getEndTimeToSubmitQuote = () => {
    const { endTime, notaryDate } = this.state;
    if (endTime !== null) {
      endTime.setDate(notaryDate.getDate());
      endTime.setMonth(notaryDate.getMonth());
      endTime.setFullYear(notaryDate.getFullYear());
      return endTime.toISOString();
    }
  };

  submitQuoteApiCall = async () => {
    this.setState({ loader: true });
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.submitQuoteApiContentType,
      token: token,
    };
    const body = {
      quote: {
        notary_requests_id: this.state.notaryRequestId,
        start_time: this.getStartTimeToSubmitQuote(),
        end_time: this.getEndTimeToSubmitQuote(),
        video_call_required: this.state.isVideoCallChecked,
        fees: this.state.fees,
        platform_fee_inclusive: this.state.isPlatformFeesChecked,
        message: this.state.message,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitQuoteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitQuoteApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.submitQuoteApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  findVideoCallCheckboxColor = () =>
    this.state.videoCallError ? "red" : "#64748B";

  findVideoCallColor = () => (this.state.videoCallError ? "red" : "#011342");

  handleSubmitQuote = () => {
    const { startTime, endTime, notarisationMethod, isVideoCallChecked, fees } =
      this.state;
    let isEverthingOk = true;
    if (
      startTime !== null &&
      endTime !== null &&
      this.isEndTimeNotGreaterThanStartTimeBy30Minutes(startTime, endTime)
    ) {
      this.setState({
        timeErrorText:
          "End time must be at least 30 minutes later than start time",
      });
      isEverthingOk = false;
    }
    if (
      (notarisationMethod.includes("REN") ||
        notarisationMethod.includes("REIN")) &&
      !isVideoCallChecked
    ) {
      this.setState({ videoCallError: true });
      isEverthingOk = false;
    }
    if (fees !== null && parseFloat(fees) === 0) {
      this.setState({ feesErrorText: "Fees must be greater than 0" });
      isEverthingOk = false;
    }
    if (isEverthingOk) {
      this.submitQuoteApiCall();
    }
  };

  toggleIsVideoCallChecked = () => {
    this.setState(
      {
        isVideoCallChecked: !this.state.isVideoCallChecked,
        videoCallError: false,
      },
      this.toggleIsSubmitQuoteButtonDisabled
    );
  };

  isVideoCallAsteriskShown = () =>
    this.state.notarisationMethod.includes("REN") ||
    this.state.notarisationMethod.includes("REIN");

  toggleIsPlatformFeesChecked = () =>
    this.setState({ isPlatformFeesChecked: !this.state.isPlatformFeesChecked });

  closeMakeQuoteModal = () => {
    this.setState({
      isMakeQuoteModalOpen: false,
      isVideoCallChecked: false,
      fees: "",
      isPlatformFeesChecked: false,
      message: "",
      startTime: null,
      endTime: null,
      isSubmitQuoteButtonDisabled: true,
      timeErrorText: "",
      feesErrorText: "",
      videoCallError: false,
      messageError: false,
    });
  };

  findMainBoxWidth = () =>
    this.state.isSideBarOpen ? "calc(100vw - 200px)" : "100vw";

  findTabColor = (tabNo: number) =>
    this.state.tabIndex === tabNo ? "#0131A8" : "#64748B";

  findSignatoriesText = (doc: FileDocumentsEntity) =>
    doc.signatory_count > 1
      ? doc.signatory_count + " Signatories"
      : doc.signatory_count + " Signatory";

  toggleIsMakeQuoteModalOpen = () =>
    this.setState({ isMakeQuoteModalOpen: true });

  handleFeesChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    this.setState({ feesErrorText: "" });
    if (configJSON.regex.test(event.target.value))
      this.setState(
        { fees: event.target.value },
        this.toggleIsSubmitQuoteButtonDisabled
      );
  };

  handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.target.value.length <= 500)
      this.setState({ message: event.target.value });
  };

  findSubtext = () =>
    this.isRequestStatusPending()
      ? undefined
      : `Cancellation charges will be applied of ${this.state.cancellationCharges}`;

  isCancelButtonShown = () =>
    this.isRoleId1() &&
    (this.isRequestStatusPending() || this.isRequestStatusInProgress());

  getNotaryRequestDetails = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.getNotaryRequestDetailsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotaryRequestDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotaryRequestDetailsApiEndPoint + this.state.notaryRequestId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNotaryRequestDetailsApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openModal = (cancelModalTitle: string, quoteId: string = "") =>
    this.setState({ cancelReqModalTitle: cancelModalTitle,cancelReqModal: true, cancelQuoteId: quoteId });

  getCancellationCharges = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.getCancellationChargesContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCancellationChargesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCancellationChargesApiEndpointPart1 +
        this.state.notaryRequestId +
        configJSON.getCancellationChargesApiEndpointPart2
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCancellationChargesApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  cancelNotaryrequest = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.cancelNotaryRequestContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putCancelNotaryRequestCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelNotaryRequestApiEndpointPart1 +
        this.state.notaryRequestId +
        configJSON.cancelNotaryRequestApiEndpointPart2
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cancelNotaryRequestApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  withdrawQuote = async () => {
    this.withdrawQuoteApiCallId = await this.apiCall({
      contentType: configJSON.withdrawQuoteApiContentType,
      method: configJSON.withdrawQuoteApiMethodType,
      endPoint:
        configJSON.withdrawQuoteApiEndpoint1 +
        this.state.cancelQuoteId +
        configJSON.withdrawQuoteApiEndpoint2,
    });
  };

  noButtonClick = () => {
    this.setState({ cancelReqModal: false });
  };

  yesButtonClick = () => {
    if (this.state.cancelQuoteId !== "") this.withdrawQuote();
    if (this.state.cancelQuoteId === "") this.cancelNotaryrequest();
    this.setState({ cancelReqModal: false, loader: true });
  };

  openSideBar = () =>
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen });

  isRoleId1 = () => this.state.roleID === 1;

  isRoleId2 = () => this.state.roleID === 2;

  isRequestStatusPending = () => this.state.status.toLowerCase() === "pending";

  isRequestStatusInProgress = () =>
    this.state.status.toLowerCase() === "in progress";

  isRequestStatusCancelled = () =>
    this.state.status.toLowerCase() === "cancelled";

  toShowMeetingTab = () =>
    !this.isRequestStatusPending() &&
    !this.isRequestStatusCancelled() &&
    this.state.status.toLowerCase() !== "";

  areTabsShown = () =>
    !this.isRequestStatusCancelled() &&
    (this.isRoleId1() ||
      this.state.quotes.length > 0 ||
      !this.isRequestStatusPending());

  isSubmittedQuotePresent = () =>
    this.state.quotes.some((quote) => quote.attributes.quote_statuses_id === 1);

  areMakeQuoteAndDeclineButtonsShown = () =>
    this.isRoleId2() &&
    !this.isSubmittedQuotePresent() &&
    this.isRequestStatusPending();

  isQuoteSubmitted = (quoteStatus: number) => quoteStatus === 1;

  navigateBack = () => {
    const previousPage = sessionStorage.getItem('previousPage');
  
    if (previousPage === '/Dashboard') {
      this.navigateToDashboard();
    } else if (previousPage === '/RequestManagement') {
      this.navigateToRequestManagement();
    } else {
      this.navigateToDashboard();
    }
    sessionStorage.removeItem('previousPage');
  };

  navigateToDashboard = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  navigateToRequestManagement = () =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "RequestManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  
  formNotaryRequestDate = () => {
    const { notaryDate } = this.state;
    const date = notaryDate.getDate();
    const month = notaryDate.getMonth() + 1;
    const year = notaryDate.getFullYear();
    return date.toString() + "/" + month.toString() + "/" + year.toString();
  };

  findDateColor = () => {
    const notaryDate = this.state.notaryDate;
    const dateToday = new Date();
    notaryDate.setHours(0,0,0,0);
    dateToday.setHours(0,0,0,0);
    return notaryDate < dateToday && this.isRoleId1() && this.isRequestStatusPending() ? "red" : "#011342";
  }

  findNotaryDateSession = () => {
    const { timing_slot } = this.state;
    return (
      this.formNotaryRequestDate() +
      " - " +
      timing_slot.charAt(0).toUpperCase() +
      timing_slot.slice(1)
    );
  };

  findMeetDateTime = () =>
    this.formNotaryRequestDate() + " | " + this.getQuoteMeetTime(this.state.meetingStartTime, this.state.meetingEndTime);

  setIsMeetingModalOpen = (value: boolean) =>
    this.setState({ isZoomModalOpen: value });

  navigateToMeeting = () => {
    this.setIsMeetingModalOpen(false);
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Cfzoomintegration92"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  getQuoteMeetTime = (startTime: string, endTime: string) => {
    const startMeetTime = new Date(startTime);
    const endMeetTime = new Date(endTime);
    let startHour: string | number = startMeetTime.getHours();
    let startMinute: string | number = startMeetTime.getMinutes();
    let startMerdian: string = "AM";
    let endHour: string | number = endMeetTime.getHours();
    let endMinute: string | number = endMeetTime.getMinutes();
    let endMerdian: string = "AM";
    if (startHour >= 12) {
      startMerdian = "PM";
    }
    if (startHour === 0) {
      startHour = startHour + 12;
    }
    if (startHour > 12) {
      startHour = startHour - 12;
    }
    if (startHour <= 9) {
      startHour = "0" + startHour.toString();
    }
    if (startMinute <= 9) {
      startMinute = "0" + startMinute.toString();
    }

    if (endHour >= 12) {
      endMerdian = "PM";
    }
    if (endHour === 0) {
      endHour = endHour + 12;
    }
    if (endHour > 12) {
      endHour = endHour - 12;
    }
    if (endHour <= 9) {
      endHour = "0" + endHour.toString();
    }
    if (endMinute <= 9) {
      endMinute = "0" + endMinute.toString();
    }
    return (
      startHour +
      ":" +
      startMinute +
      " " +
      startMerdian +
      " - " +
      endHour +
      ":" +
      endMinute +
      " " +
      endMerdian
    );
  };

  getQuoteDateTime = (quoteTime: string) => {
    const dateTime = new Date(quoteTime);
    let hour: string | number = dateTime.getHours();
    let minute: string | number = dateTime.getMinutes();
    let merdian: string = "AM";
    if (hour >= 12) {
      merdian = "PM";
    }
    if (hour === 0) {
      hour = hour + 12;
    }
    if (hour > 12) {
      hour = hour - 12;
    }
    if (hour <= 9) {
      hour = "0" + hour.toString();
    }
    if (minute <= 9) {
      minute = "0" + minute.toString();
    }
    return (
      this.formNotaryRequestDate() + " - " + hour + ":" + minute + " " + merdian
    );
  };

  getQuoteTime = () => {
    const { quoteStartTime, quoteEndTime } = this.state;
    const quoteST = new Date(quoteStartTime);
    const quoteET = new Date(quoteEndTime);
    let startHour: string | number = quoteST.getHours();
    let startMinute: string | number = quoteST.getMinutes();
    let startMerdian: string = "AM";
    let endHour: string | number = quoteET.getHours();
    let endMinute: string | number = quoteET.getMinutes();
    let endMerdian: string = "AM";
    if (startHour >= 12) {
      startMerdian = "PM";
    }
    if (endHour >= 12) {
      endMerdian = "PM";
    }
    if (startHour === 0) {
      startHour = startHour + 12;
    }
    if (startHour > 12) {
      startHour = startHour - 12;
    }
    if (endHour === 0) {
      endHour = endHour + 12;
    }
    if (endHour > 12) {
      endHour = endHour - 12;
    }
    if (startHour <= 9) {
      startHour = "0" + startHour.toString();
    }
    if (startMinute <= 9) {
      startMinute = "0" + startMinute.toString();
    }
    if (endHour <= 9) {
      endHour = "0" + endHour.toString();
    }
    if (endMinute <= 9) {
      endMinute = "0" + endMinute.toString();
    }
    return (
      startHour +
      ":" +
      startMinute +
      " " +
      startMerdian +
      " - " +
      endHour +
      ":" +
      endMinute +
      " " +
      endMerdian
    );
  };

  a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) =>
    this.setState({ tabIndex: newValue });

  handleSuccessFailureModalButtonClick = () => {
    if (
      this.state.successFailModalType === SuccessFailModalType.PaymentSuccess
    ) {
      this.refreshPage();
    } else if (
      this.state.successFailModalType === SuccessFailModalType.PaymentFail
    ) {
      this.redirectToPaymentOptions();
    }
    this.setState({ isSuccessFailModalOpen: false });
  };

  isQuotesNullForEndUser = () =>
    this.isRoleId1() &&
    this.isRequestStatusPending() &&
    this.state.quotes.length <= 0;

  areQuotesAvailableForEndUser = () =>
    this.isRoleId1() &&
    this.isRequestStatusPending() &&
    this.state.quotes.length > 0;

  areQuotesAvailableForNotaryUser = () =>
    this.isRoleId2() &&
    this.isRequestStatusPending() &&
    this.state.quotes.length > 0;

  getQuoteListOrSetLoaderToFalse = () => {
    if (this.isRequestStatusPending()) this.getQuotesList();
    else this.setLoader(false);
    if (this.isRequestStatusInProgress()) this.getMeetingTime();
  };

  refreshPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "RequestDetails"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  isValidResponse = (responseJson: ValidResponseType) =>
    responseJson && !responseJson.errors;

  responseSuccessCallBack = (
    apiRequestCallId: string,
    responseJson: IProfile &
      NotaryRequestDetails &
      CancellationCharge &
      CancelNotaryRequest &
      IAllServices &
      ISubmitQuoteResponse &
      IGetQuotesList &
      IAcceptQuote &
      IMeetingCreated
  ) => {
    switch (apiRequestCallId) {
      case this.getProfileApiCallId:
        this.setState({
          userName: responseJson.data.attributes.full_name,
          userProfilePic: responseJson.data.attributes.photo.url,
          roleID: responseJson.data.attributes.role_id,
        });
        break;
      case this.getNotaryRequestDetailsCallId:
        const docs = responseJson.data.attributes.file_documents;
        const totalSignatoryCount = docs.reduce((total, doc) => {
          return total + doc.signatory_count;
        }, 0);
        this.setState(
          {
            notaryRequestId: responseJson.data.id,
            status: responseJson.data.attributes.status,
            typeOfNotaryService:
              responseJson.data.attributes.notary_service_name,
            notarisationMethod:
              responseJson.data.attributes.notarisation_method
                .notarisation_method,
            notaryDate: new Date(responseJson.data.attributes.date),
            jurisdiction: responseJson.data.attributes.juridiction.jurisdiction,
            documents: responseJson.data.attributes.file_documents,
            description: responseJson.data.attributes.notes,
            numberOfSignatory: totalSignatoryCount,
            timing_slot: responseJson.data.attributes.timing_slot,
            areTabsDisabled:
              responseJson.data.attributes.status.toLowerCase() === "pending" ||
              responseJson.data.attributes.status.toLowerCase() === "cancelled",
          },
          () => {
            this.getCancellationCharges();
            this.getQuoteListOrSetLoaderToFalse();
          }
        );
        break;
      case this.getCancellationChargesApiCallId:
        this.setState({
          cancellationCharges: responseJson.cancellation_charges,
        });
        break;
      case this.putCancelNotaryRequestCallId:
        if (responseJson.message === "Notary request cancelled successfully.") {
          this.setState({
            successFailModalImage: successImage,
            successFailModalText: "Success!",
            successFailModalSubText:
              "Your order has been cancelled successfully.",
            successFailModalButtonText: "Check Status",
            loader: false,
            isSuccessFailModalOpen: true,
            status: "CANCELLED",
          });
        }
        break;
      case this.getServicesApiCallId:
        this.setState({ serviceData: responseJson.data });
        break;
      case this.submitQuoteApiCallId:
        if (responseJson.message === "Quote submitted successfully.") {
          this.closeMakeQuoteModal();
          this.setState({
            successFailModalImage: successImage,
            successFailModalText: "Your Quote Submitted Successfully!",
            successFailModalSubText:
              "You can check the status from the below button.",
            successFailModalButtonText: "Check Status",
            loader: false,
            isSuccessFailModalOpen: true,
          });
          this.getQuotesList();
        }
        break;
      case this.getQuotesListApiCallId:
        const quotes = responseJson.data;
        quotes.sort(
          (a: IQuote, b: IQuote) =>
            new Date(b.attributes.created_at).getTime() -
            new Date(a.attributes.created_at).getTime()
        );
        this.setState({ quotes: quotes, loader: false });
        break;
      case this.acceptQuoteApiCallId:
        if (responseJson.message === "Quote accepted successfully.")
          this.generateMeeting();
        this.getNotaryRequestDetails();
        break;
      case this.generateMeetingApiCallId:
        if (responseJson.message.toLowerCase() === "you have successfully created zoom meeting")
          this.getMeetingTime();
        break;
      case this.withdrawQuoteApiCallId:
        if (responseJson.message === "Quote withdrawn successfully.") {
          this.setState({
            successFailModalImage: successImage,
            successFailModalText: "Success!",
            successFailModalSubText:
              "Your order has been cancelled successfully.",
            successFailModalButtonText: "Check Status",
            loader: false,
            isSuccessFailModalOpen: true,
          });
          this.getQuotesList();
        }
        break;
    }
  };

  responseFailureCallBack = (
    apiRequestCallId: string,
    responseJson: ISubmitQuoteFailureResponse
  ) => {
    if (apiRequestCallId === this.submitQuoteApiCallId) {
      this.setState({
        successFailModalImage: failureImage,
        successFailModalText: "Your Quote Submission Failed!",
        successFailModalSubText: responseJson.errors[0],
        successFailModalButtonText: "OK",
        loader: false,
        isSuccessFailModalOpen: true,
      });
    }
  };
  // Customizable Area End
}
