import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  Paper,
  Typography,
  Select,
  Input,
  MenuItem,
} from "@material-ui/core";
import { image, notary } from "./assets";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ModeNightIcon from "@material-ui/icons/Brightness2";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CreateIcon from "@material-ui/icons/Create";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

export const configJSON = require("./config");
// Customizable Area End

import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";


export default class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAccount = () => {
    return (
      <ContentBox>
        <Box className="imgBox">
          <img
            src={this.state.avatar?.url ? this.state.avatar?.url : image}
            alt=""
          />
          {!this.state.online ? (
            <Box className="status" />
          ) : (
            <ModeNightIcon className="offline" />
          )}
        </Box>
        <Box className="statusText">
          <span className="greenText">{configJSON.setYourself}</span>{" "}
          <span
            className="blueText"
            data-testID="onlineBtn"
            onClick={this.setOnline}
          >
            {this.state.online ? configJSON.onlineText : configJSON.offline}
          </span>
          {this.state.online ? (
            <Box className="statusGreen" />
          ) : (
            <ModeNightIcon className="blueText" />
          )}
        </Box>
        <OptionsBox>
          <Box
            className="option"
            component={Paper}
            data-testID="profileBtn"
            onClick={this.handleProfileOpen}
          >
            <Box className="nameIcon">
              <AccountCircleOutlinedIcon className="iconStyle" />
              <p className="profileText">{configJSON.myProfile}</p>
            </Box>
            <NavigateNextIcon />
          </Box>
          <Box
            className="option"
            component={Paper}
            onClick={this.goToTransactionHistory}
          >
            <Box className="nameIcon">
              <CreditCardOutlinedIcon className="iconStyle" />
              <p className="profileText">{this.state.roleID === 2 ? "Transaction History" : "Payments"}</p>
            </Box>
            <NavigateNextIcon />
          </Box>
          {this.state.roleID === 2 && (
            <Box
              className="option"
              component={Paper}
              data-testID="notaryNavigateBtn"
              onClick={this.notaryNavigation}
            >
              <Box className="nameIcon">
                <img src={notary} className="iconStyle" />
                <p className="profileText">{configJSON.notaryServices}</p>
              </Box>
              <NavigateNextIcon />
            </Box>
          )}
          <Box
            className="option"
            component={Paper}
            onClick={this.handleSettingsBtn}
          >
            <Box className="nameIcon">
              <SettingsOutlinedIcon className="iconStyle" />
              <p className="profileText">{configJSON.settings}</p>
            </Box>
            <NavigateNextIcon />
          </Box>
        </OptionsBox>
      </ContentBox>
    );
  };

  renderOption = () => {
    return (
      <OptionsBoxEdit>
        <OptionEdit>
          <p className="option">{configJSON.fullName}</p>
          <Input
            className="input"
            data-testID="fullNameTestID"
            disableUnderline
            inputProps={{
              maxLength: 30,
            }}
            readOnly={!this.state.isEditing}
            value={this.state.fullName !== null && this.state.fullName}
            onChange={this.handleNameChange}
          />
        </OptionEdit>
        {this.state.isFullName && (
          <p className="errorText1">{configJSON.errorFullName}</p>
        )}
        <OptionEdit>
          <p className="option">{configJSON.emailID}</p>
          <Input
            className={this.state.emailProfile ? "inputEmail" : "input"}
            data-testID="emailTestID"
            disableUnderline
            readOnly={true}
            value={this.state.emailProfile}
            onChange={this.handleEmailChange}
          />
        </OptionEdit>
        {(this.state.user_type === "notary" || this.state.user_type === "business") 
        && (
      <OptionEdit>
      <p className="option">Business / Company Name</p>
      <Input
        className="input"
        data-testID="companyTestID"
        disableUnderline
        readOnly={!this.state.isEditing}
        value={this.state.companyName}
        onChange={this.handleCompanyChange}
      />
    </OptionEdit>
  )
}
        {this.state.isCompany && (
          <p className="errorText1">{configJSON.companyError}</p>
        )}
        <OptionEdit>
          <p className="option">{configJSON.mobileText}</p>
          <Select
            className="input code"
            data-testID="mobileCodeTestID"
            value={this.getCountryCodeValue(this.state.countryCode)}
            readOnly={!this.state.isEditing}
            IconComponent={ExpandMoreIcon}
            onChange={this.handleCodeChange}
            displayEmpty
            fullWidth
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              keepMounted: true,
            }}
          >
            {this.state.countryCodes.map((item) => (
              <MenuItem key={item.country_code} value={item.country_code}>
                {"+"}
                {item.country_code}
              </MenuItem>
            ))}
          </Select>
          <Input
            className="input mobileNumber"
            disableUnderline
            data-testID="mobileNoTestID"
            type="phone_number"
            readOnly={!this.state.isEditing}
            value={
              this.state.phoneNumberProfile
                ? this.state.phoneNumberProfile
                : null
            }
            onChange={this.handlePhoneNumberChange}
          />
        </OptionEdit>
        {this.state.isPhone && (
          <p className="errorText1">{configJSON.errorMobile}</p>
        )}
        <OptionEdit>
          <p className="option">{configJSON.addressLine}</p>
          <Input
            className="input"
            disableUnderline
            data-testID="addressTestID"
            inputProps={{
              maxLength: 50,
            }}
            readOnly={!this.state.isEditing}
            value={this.getValueOrDefault(this.state.addressOne)}
            onChange={this.handleAddressOneChange}
          />
        </OptionEdit>
        {this.state.isAddressOne && (
          <p className="errorText1">{configJSON.errorAddress}</p>
        )}
        <OptionEdit>
          <p className="option">{configJSON.addressLineTwo}</p>
          <Input
            className="input"
            disableUnderline
            data-testID="addressTwoTestID"
            inputProps={{
              maxLength: 50,
            }}
            readOnly={!this.state.isEditing}
            value={this.getValueOrDefault(this.state.addressTwo)}
            onChange={this.handleAddressTwoChange}
          />
        </OptionEdit>
        <OptionEdit>
          <Box className="city left">
            <p className="option">{configJSON.city}</p>
            <Input
              className="input cityInput"
              disableUnderline
              data-testID="cityTestID"
              inputProps={{
                maxLength: 30,
              }}
              readOnly={!this.state.isEditing}
              value={this.getValueOrDefault(this.state.city)}
              onChange={this.handleCityChange}
            />
          </Box>
          <Box className="city">
            <p className="option">{configJSON.country}</p>
            <Select
              className="input codeCountry"
              data-testID="countryTestID"
              value={this.getValueOrDefault(this.state.country)}
              readOnly={!this.state.isEditing}
              IconComponent={ExpandMoreIcon}
              onChange={this.handleCountryChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                keepMounted: true,
              }}
            >
              {this.state.countries.map((item) => (
                <MenuItem
                  key={item.attributes.name}
                  value={item.attributes.name}
                >
                  {item.attributes.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {this.state.isCity && (
            <p className="errorTexte">{configJSON.errorCity}</p>
          )}
        </OptionEdit>
        {this.state.isCity && (
          <p className="errorText1">{configJSON.errorCity}</p>
        )}
        <OptionEdit>
          <p className="option">{configJSON.postCode}</p>
          <Input
            className="input"
            disableUnderline
            data-testID="postCodeTestId"
            inputProps={{
              maxLength: 8,
            }}
            readOnly={!this.state.isEditing}
            value={this.getValueOrDefault(this.state.postalCode)}
            onChange={this.handlePostalCodeChange}
          />
        </OptionEdit>
        {this.state.isPostCode && (
          <p className="errorText1">{configJSON.errorPost}</p>
        )}
      </OptionsBoxEdit>
    );
  };

  renderOptionEdit = () => {
    return (
      <OptionsBoxEdit>
        {this.state.isEditing ? (
          <button
            className="btn btnSave"
            data-testID="saveTestID"
            onClick={this.handleChangeSave}
          >
            <p>Save Changes</p>
          </button>
        ) : (
          <button
            className="btn btnSave"
            data-testID="editTestId"
            onClick={this.handleChangeEditing}
          >
            <CreateIcon />
            <p>Edit Profile</p>
          </button>
        )}
        {this.state.isEditing && (
          <button
            data-testID="cancelBtn"
            className="btn btnCancel"
            onClick={this.handleCancel}
          >
            {configJSON.cancel}
          </button>
        )}
      </OptionsBoxEdit>
    );
  };

  renderEditProfile = () => {
    return (
      <ContentBoxEdit>
        <InnerContentBoxEdit>
          <ImageBoxEdit>
            <label htmlFor="file" className="profileLableContainer">
              <img
                src={this.state.avatar?.url ? this.state.avatar?.url : image}
                alt=""
              />
              {this.state.isEditing && (
                <Box className="camIconBox">
                  <CameraAltOutlinedIcon />
                  <p>{configJSON.editPicture}</p>
                </Box>
              )}
            </label>
            {this.state.isEditing && (
              <input
                type="file"
                id="file"
                data-testID="filechangeBtn"
                className="imgInput"
                accept=".png, .jpeg, .jpg"
                readOnly={!this.state.isEditing}
                onChange={this.handleAvatarChange}
              />
            )}
          </ImageBoxEdit>
          {this.state.invalidFile && (
            <p className="invalidFile">{configJSON.invalidDes}</p>
          )}
          {this.renderOption()}
          {this.renderOptionEdit()}
        </InnerContentBoxEdit>
      </ContentBoxEdit>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <RequestSectionBox>
        <Box className={this.state.isSideBarOpen ? "sideBarOpen" : "sideBar"}>
          <NavigationMenu navigation={this.props.navigation} id="" />
        </Box>
        <Box className="accountContainer">
          <Box 
            className="headerContainer"
            data-testID="headerContainerBtn"
            onClick={this.handleBackArrow}
          >
            {this.state.profileOpen === true && (
              <ArrowBackIosIcon className="svgDesign" />
            )}
            <HeaderMyAccount>
              {this.state.profileOpen !== true
                ? configJSON.myAccount
                : configJSON.myProfile}
            </HeaderMyAccount>
          </Box>

          {this.state.profileOpen !== true
            ? this.renderAccount()
            : this.renderEditProfile()}
        </Box>
      </RequestSectionBox>
    );
    // Customizable Area End
  }
  
}
// Customizable Area Start
const RequestSectionBox = styled(Box)({
  display: "flex",
  height: "100vh",
  overflowY: "hidden",
  "& .accountContainer": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "whitesmoke",
    justifyContent: "flex-start",
    overflowY: "scroll",
    padding: "35px 0px",
    "& .headerContainer": {
      display: "flex",
      alignItems: "center",
      marginLeft: "24px",
      justifyContent: "flex-start",
      cursor: "pointer",
    },
  },
  "& .svgDesign": {
    cursor: "pointer",
  },
});

const HeaderMyAccount = styled(Typography)({
  fontSize: "24px",
  fontFamily: "Inter",
  fontWeight: 700,
  color: "#011342",
});

const ContentBox = styled(Box)({
  margin: "100px auto",
  background: "#fff",
  boxShadow: "0px 2px 2px 0px #00000014",
  width: "514px",
  padding: "62px 24px 24px 24px",
  height: "547px",
  "& .imgBox": {
    height: "92px",
    width: "92px",
    margin: "0 auto",
    position: "relative",
  },
  "& .imgBox img": {
    height: "92px",
    width: "92px",
    borderRadius: "50%",
    border: "2px solid #CCD3E3",
  },
  "& .status": {
    height: "12px",
    width: "12px",
    background: "#34D399",
    borderRadius: "50%",
    position: "absolute",
    right: "2px",
    bottom: "2px",
    border: "2px solid #fff",
  },
  "& .offline": {
    height: "12px",
    width: "12px",
    backgroundColor: "#fff",
    borderRadius: "50%",
    position: "absolute",
    right: "2px",
    bottom: "2px",
    border: "2px solid #fff",
  },
  "& .statusText": {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 400,
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "14px",
    marginBottom: "24px",
    "& .greenText": {
      color: "#34D399",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 400,
    },
    "& .blueText": {
      color: "#012275",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      cursor: "pointer",
    },
    "& .statusGreen": {
      height: "12px",
      width: "12px",
      background: "#34D399",
      borderRadius: "100%",
      border: "2px solid #fff",
    },
  },
});

const OptionsBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "18px",
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Inter",
  "& .option": {
    width: "450px",
    height: "56px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 24px",
  },
  "& .nameIcon": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  "& .profileText": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Inter",
    color: "#011342",
    margin: "0",
    cursor: "pointer",
  },
  "& .iconStyle": {
    height: "20px",
    width: "20px",
  },
});

const ContentBoxEdit = styled(Box)({
  height: "950px",
  width: "504px",
  margin: "0 auto",
  background: "#fff",
  borderRadius: "8px",
  padding: "52px 32px 32px 32px",
  marginTop: "32px",
  boxSizing: "border-box",
});

const InnerContentBoxEdit = styled(Box)({
  width: "440px",
  margin: "0 auto",
  "& .invalidFile": {
    color: "red",
    textAlign: "center",
    width: "100%",
    fontSize: "10px",
    fontFamily: "Inter",
  },
});

const ImageBoxEdit = styled(Box)({
  height: "96px",
  width: "96px",
  margin: "0 auto",
  position: "relative",
  borderRadius: "50%",
  border: "1px solid",
  "& img": {
    height: "96px",
    width: "96px",
    borderRadius: "50%",
  },

  "& .camIconBox": {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    fontWeight: 700,
    width: "96px",
    height: "96px",
    bottom: "22px",
    fontSize: "14px",
    lineHeight: "14px",
    borderRadius: "50%",
    marginBottom: "-20px",
    backgroundColor: "black",
    opacity: 0.4,
  },
  "& .camIconBox p": {},
  "& .imgInput": {
    display: "none",
  },
  "& .profileLableContainer": {
    height: "0px",
    display: "block",
  },
});

const OptionsBoxEdit = styled(Box)({
  marginTop: "32px",
  display: "flex",
  flexDirection: "column",
  gap: "13px",
  "& .phoneNumber": {
    display: "flex",
  },
  "& .errorText": {
    color: "red",
    fontSize: "11px",
    fontWeight: 500,
    fontFamily: "Inter",
    margin: "4px",
  },
  "& .errorTexte": {
    color: "white",
    fontSize: "11px",
    fontWeight: 500,
    fontFamily: "Inter",
    margin: "4px",
  },
  "& .errorText1": {
    color: "red",
    fontSize: "11px",
    fontWeight: 500,
    fontFamily: "Inter",
    marginBottom: "7px",
  },
  "& .btn": {
    height: "52px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
    fontFamily: "Inter",
  },
  "& .btnSave": {
    color: "white",
    background: "#012275",
    border: "none",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  },
  "& .btnCancel": {
    border: "1px solid #5D5D5D",
    background: "#fff",
    boxSizing: "border-box",
  },
});

const OptionEdit = styled(Box)({
  width: "440px",
  height: "70px",
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
    borderBottom: "0px",
  },
  "& .MuiInput-underline::before": {
    borderBottom: "0px",
  },
  "& .option": {
    fontWeight: 700,
    fontSize: "14px",
    marginBottom: "6px",
    fontFamily: "Inter",
    color: "#011342",
  },
  "& .input": {
    border: "1px solid #CBD5E1",
    width: "440px",
    height: "44px",
    color: "#011342",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "8px",
    padding: "12px 14px",
    boxSizing: "border-box",
    "& .MuiInputBase-input": {
      padding: "0px",
      backgroundColor: "#fff",
      fontFamily: "Inter",
      color: "#011342",
    },
  },
  "& .mobileNumber": {
    width: "332px",
  },
  "& .inputEmail": {
    color: "#64748B !important",
    border: "1px solid #CBD5E1",
    width: "440px",
    height: "44px",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "8px",
    padding: "12px 14px",
    boxSizing: "border-box",
  },
  "& .code": {
    width: "100px",
    height: "44px",
    marginRight: "8px",
    padding: "10px",
    backgroundColor: "#fff",
    "& .MuiSelect-icon": {
      right: "10px",
      color: "#011342",
    },
  },
  "& .codeCountry": {
    width: "214px",
    height: "44px",
    marginRight: "8px",
    padding: "10px",
    backgroundColor: "#fff",
    "& .MuiSelect-icon": {
      right: "10px",
      color: "#011342",
    },
  },
  "& .city ,.cityInput": {
    width: "214px",
    display: "inline-block",
    "& .MuiInputBase-input": {
      padding: "0px",
    },
  },

  "& .left": {
    marginRight: "12px",
  },
});
// Customizable Area End
