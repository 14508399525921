// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Box, TextField, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, AppBar, Toolbar, Select, InputLabel, FormControl, Tabs, Tab, InputAdornment, Typography, MenuItem, CircularProgress } from "@material-ui/core";
export const configJSON = require("./config");
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import TransactionHistoryController, { Props } from "./TransactionHistoryController";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SearchIcon from '@material-ui/icons/Search';
import Loader from "../../../components/src/Loader.web";



const theme = createTheme({
    palette: {},
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

    overrides: {
        MuiTabs: {
            "flexContainer": {
            },
            root: {

                "& .MuiTabs-indicator": {
                    display: 'none'
                },
            },
        },

        MuiTab: {
            root: {
                "&.MuiTab-textColorPrimary": {},
                "&.MuiTab-textColorPrimary.Mui-selected": {},
                "@media(min-width: 600px)": {
                    "&.MuiTab-root": {}
                }

            }
        },
        MuiTable: {
            "root": {
                "& .MuiTableCell-body": {
                    color: "#011342",
                    fontSize: '16px',
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    height: '72px'
                }
            }
        },
        MuiPopover: {
            root: {
                "& .MuiPopover-paper": {
                    top: '140px !important'
                },
                "& .MuiMenuItem-root": {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: "inter",
                    height: '37px'
                },
                "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
                    background: '#0131A8',
                    color: '#fff'
                },
                "@media(max-width: 750px)": {
                    "& .MuiPopover-paper": {
                        top: '240px !important'
                    },
                }
            }
        }
    }
});
// Customizable Area End

export default class TransactionHistoryWeb extends TransactionHistoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTable = () => {
        return (
            <>
                <TableContainer
                    style={{ height: 'calc(100vh - 200px)', maxHeight: '725px' }}
                    component={Paper}
                    ref={this.tableContainerRef}
                    data-test-id="scrollsection"
                >
                    <Table stickyHeader aria-label="simple table sticky table">
                        <TableHead>
                            <TableRow >
                                <TableCell
                                    style={webstyle.tableHeadStyle}
                                >{configJSON.tableColumn_1}
                                </TableCell>
                                <TableCell
                                    style={webstyle.tableHeadStyle}
                                >
                                    {this.state.userRoleId === 2 ? "Transaction Date" : "Payment Date"}
                                </TableCell>
                                <TableCell
                                    style={webstyle.tableHeadStyle}
                                >
                                    {this.state.userRoleId === 2 ? configJSON.tableColumn_3 : "Payment Method"}
                                </TableCell>
                                <TableCell
                                    style={webstyle.tableHeadStyle}
                                >{configJSON.tableColumn_4}</TableCell>
                                <TableCell style={webstyle.tableHeadStyle}
                                >{configJSON.tableColumn_5}</TableCell>
                                <TableCell style={webstyle.tableHeadStyle}
                                >{configJSON.tableColumn_6}</TableCell>
                                <TableCell style={webstyle.tableHeadStyle}
                                >
                                    {this.state.userRoleId === 2 ? configJSON.tableColumn_7 : "Amount"}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.transactionsList?.length > 0 && this.state.transactionsList.map((row: any) => (
                                <TableRow>
                                    <TableCell align="center">{row?.request_type}</TableCell>
                                    <TableCell align="center">{this.convertDate(row.payment_date)}</TableCell>
                                    <TableCell align="center">{row?.payment_method || 'not entered'}</TableCell>
                                    <TableCell align="center">{row?.transaction_number}</TableCell>
                                    <TableCell align="center">{row?.notary_request_id}</TableCell>
                                    <TableCell align="center">
                                        <div
                                            style={
                                                {
                                                    padding: '2px 12px 2px 12px',
                                                    backgroundColor: row.status === "PAID" ? "#D1FAE5" : "#FEE2E2",
                                                    color: row.status === "PAID" ? "#059669" : '#DC2626',
                                                    fontWeight: 700,
                                                    fontSize: '12px',
                                                    width: '54px',
                                                    margin: 'auto',
                                                    borderRadius: '40px'
                                                }}
                                        >
                                            {row.status}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">{row.amount}</TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                    {this.state.transactionsList.length === 0 &&
                        <Box style={{ padding: '20px' }}>
                            <Typography
                                style={webstyle.noDataStyle}>
                                {configJSON.noDataText}
                            </Typography>
                        </Box>
                    }
                    {this.state.isLoading && (
                        <div style={{ textAlign: 'center', padding: '10px' }}>
                            <CircularProgress />
                        </div>
                    )}
                    {!this.state.hasMore && (
                        <div style={{ textAlign: 'center', padding: '10px' }}>
                            <p>{configJSON.noMoreTransactionsText}</p>
                        </div>
                    )}
                </TableContainer>
            </>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                <MainStyle>
                    <Box className={this.state.isSideBarOpen ? "sideBarOpen" : "sideBar"}>
                        <NavigationMenu navigation={this.props.navigation} id="" />
                    </Box>
                    <Box style={webstyle.rightSideBox}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <Box
                                style={{ gap: '8px', display: 'flex', alignItems: 'center' }}
                                onClick={this.goToUserProfile}
                            >
                                <ArrowBackIosIcon />
                                <h2 style={{ fontWeight: 700 }}>
                                    {this.state.userRoleId === 1 ? "Payments" : "Transaction History"}
                                </h2>
                            </Box>
                            <Box>
                                <div >
                                    <SearchField
                                        data-test-id="orderId-input"
                                        value={this.state.orderId}
                                        onChange={this.handleOrderIdInputChange}
                                        InputProps={{

                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon style={{ color: '#5D5D5D' }} />
                                                </InputAdornment>),

                                        }}
                                        placeholder="Search Order ID"
                                        variant="outlined"
                                        style={{ width: '360px' }}
                                    />
                                </div>
                            </Box>

                        </Box>
                        {this.state.loader && <Loader loading={this.state.loader} />}

                        <Box >
                            <AppBar position="static" className="heading" style={webstyle.appBarStyle}>

                                <Toolbar style={webstyle.toolbarStyle}>
                                    <Box>
                                        <div>
                                            <TabsContainer
                                                value={this.state.tabeValue}
                                                textColor="primary"
                                                onChange={this.handleEventTabChange}
                                                aria-label="disabled tabs example"
                                                data-test-id="tabs-input"
                                            >
                                                <Tab style={{textTransform: 'capitalize'}} className={`${this.state.tabeValue === 0 && "All"}`} label="All" />
                                                <Tab className={`${this.state.tabeValue === 1 && "PAID"}`} label="PAID" />
                                                <Tab className={`${this.state.tabeValue === 2 && "FAILED"}`} label="FAILED" />
                                            </TabsContainer>

                                        </div>
                                    </Box>

                                    <Box >
                                        <div>
                                            <SelectFormControl variant="outlined" className="select-form-control">
                                                <InputLabel id="demo-simple-select-outlined-label">{configJSON.sortByText}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={this.state.sortData}
                                                    onChange={this.handleSelectChange}
                                                    label="Sort By"
                                                    className="select-control"
                                                    data-test-id="sortBy-input"
                                                >
                                                    <MenuItem value="ascending">{configJSON.option_1}</MenuItem>
                                                    <MenuItem value="decending">{configJSON.option_2}</MenuItem>
                                                    <MenuItem value="high_to_low">{configJSON.option_3}</MenuItem>
                                                    <MenuItem value="low_to_high">{configJSON.option_4}</MenuItem>
                                                </Select>
                                            </SelectFormControl>

                                        </div>
                                    </Box>
                                </Toolbar>
                            </AppBar>
                        </Box>
                        {this.renderTable()}
                    </Box>
                </MainStyle>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const TabsContainer = styled(Tabs)({
    width: '219px',
    backgroundColor: "transparent",
    color: "#012275",

    '& .Mui-selected': {
        color: "#012275",
        fontWeight: 600
    },
    '& .MuiTab-root': {
        width: "fit-content",
        padding: "10px 12px 12px 12px",
        "@media (min-width: 600px)": {
            minWidth: "fit-content"
        },
    },
    '& .MuiTabs-indicator': {

    },
    '& .ALL': {
        color: '#012275',
        borderBottom: '2px solid #012275',
        textTransform: 'capitalize'
    },
    '& .PAID': {
        color: '#059669',
        borderBottom: '2px solid #059669'
    },
    '& .FAILED': {
        color: '#DC2626',
        borderBottom: '2px solid #DC2626'
    }
});

const SearchField = styled(TextField)({
    width: 'fit-content',
    '& .MuiInputBase-root': {
        width: '360px',
        height: '40px',
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: '#D1FAE5'
        }
    }
});

const SelectFormControl = styled(FormControl)({
    top: '-8px',
    "& .select-control": {
        fontSize: '16px',
        fontFamily: 'Inter',
        height: '44px',
    },
    "@media(max-width: 750px)": {
        top: 0
    }
});

const MainStyle = styled(Box)({
    display: 'flex',
});

const webstyle = {
    tableHeadStyle: {
        color: '#012275',
        fontWeight: 600,
        textAlign: 'center' as 'center',
        width: '134px',
        fontSize: '16px',
    },
    noDataStyle: {
        fontFamily: "Inter",
        fontSize: '16px',
        color: '#012275',
        textAlign: 'center' as 'center',
        fontWeight: 500
    },
    appBarStyle: {
        backgroundColor: '#F1F5F9',
        padding: '24px',
        width: '100%',
        paddingBottom: 0,
    },
    toolbarStyle: {
        display: 'flex ',
        justifyContent: 'space-between',
        flexWrap: 'wrap' as 'wrap',
        gap: '15px'
    },
    rightSideBox: {
        padding: '0 24px',
        width: 'calc(100% - 200px)',
        boxSizing: 'border-box' as 'border-box'
    }
}

// Customizable Area End
