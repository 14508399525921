Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfzoomintegration92";
exports.labelBodyText = "cfzoomintegration92 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getProfileContentType = "application/json";
exports.getProfileApiEndPoint = "bx_block_profile/profiles/get_profile";
exports.getProfileApiMethodType = "GET";
exports.zoomEndPoint = "bx_block_cfzoomintegration92/zoom_meetings?notary_request_id=";
// Customizable Area End