Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.postMethod = "POST";
exports.putMethod = "PUT";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "Dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = "";
exports.labelBodyText = "dashboard Body";
exports.allRequestCountAPI =
  "bx_block_menu_ordering/notary_requests/request_counts";
exports.progressRequest = "Requests In progress";
exports.completeRequest = "Completed Requests";
exports.progressRequestCountAPI =
  "bx_block_menu_ordering/notary_requests/all_request_for_in_progress";
exports.yourRequestText = "Your Requests";
exports.urgency = "Urgency";
exports.orderID = "Order ID";
exports.notaryService = "Notary Services";
exports.method = "Method";
exports.requestDate = "Request Date";
exports.status = "Status";
exports.action = "Action";
exports.view = "View";
exports.edit = "Edit";
exports.noRequest = "No requests yet!";
exports.bookNow = "Book Now";
exports.textEmpty =
  "Create your first notary request with renotary by clicking";
exports.getAllNotaryRequestApiEndpoint = "bx_block_menu_ordering/notary_requests?page=1&per_page=10";
exports.allServiceApiEndpoint =
  "bx_block_landing_page/admin_landing_pages/get_services";
exports.notaryServicesText = "Notary Services";
exports.getjuridictionAPIEndPoint =
  "bx_block_menu_ordering/notary_requests/all_jurisdictions";
exports.getNotrisationMetodEndpoint =
  "bx_block_menu_ordering/notary_requests/all_notarisation_method";
exports.testing = "Testing";
exports.newNotaryRequest = "New Notary Request";
exports.typeOfNotaryService = "Type of notary service";
exports.selectTheOptionsThatBestDescribesYourNotaryService =
  " Select the options that best describes your notary service.";
exports.methodOfNotarisations = "Method of Notarisations";
exports.selectTheMethodOfNotarisations = "Select the method of notarisations.";
exports.selectTheDateAndTimeSlotForYourNotaryService =
  "Select the date and time slot for your notary service.";
exports.selectTheDateBetweenTomorrowAnd30DaysFromTomorrow =
  "Select the date between tomorrow and 30 days from tomorrow";
exports.addSpecialInstructionOrAdditionalInformation =
  "Add special instruction or additional information (optional)";
exports.chooseYourJurisdiction = "Choose your Jurisdiction";
exports.chooseYourJurisdictionWhereYouNeedANotaryService =
  "Choose your jurisdiction where you need a notary service.";
exports.selectTheNumberOfDocumentsToBeNotarised =
  "Select the number of documents to be notarised.";
exports.cancel = "Cancel";
exports.next = "Next";
exports.morning = "Morning";
exports.afternoon = "Afternoon";
exports.evening = "Evening";
exports.save = "Save";
exports.step1 = "STEP 1";
exports.step2 = "STEP 2";
exports.step3 = "STEP 3";
exports.docuText = "Document #1";
exports.yourDocumentsToBeNotarised = "Your Documents to be Notarised";
exports.remove = "Remove";
exports.docText2 = "Document #2";
exports.uploadADocumentOrDragItHere = "Upload a document or Drag it here";
exports.docxPngJpgJpegPdfMaxUploadSize2MB =
  "docx, png, jpg, jpeg, pdf, Max upload size 2MB";
exports.addMoreDocuments = "Add More Documents";
exports.back = "Back";
exports.invalidFileSizeChooseAnotherFile =
  "Invalid file size, Choose another file.";
exports.createRequest = "Create Request";
exports.editRequest = "Update";
exports.alsoAsignatory = "Also a signatory";
exports.checkboxText =
  "I understand that I am requesting remote digital notarisation. I will need to have audio/video capability for the actual signing.";
exports.checkBoxText1 =
  "By uploading these files, I understand that a Notary Public may modify permissions and settings of uploaded files and/or merge form content with document master in order to complete the online document notarisation process.";
exports.getProfileEndPoint = "bx_block_profile/profiles/get_profile";
exports.doYouReallyWantToCancelTheRequest =
  "Do you really want to cancel the request?";
exports.doYouReallyWantToCancelEditingTheRequest =
  "Do you really want to cancel editing the request?";
exports.yesCancelRequest = "Yes, Cancel request";
exports.yesCancelEditing = "Yes, Cancel Editing";
exports.no = "No";
exports.saveDraft = "Save Draft";
exports.notaryRequestCreatedSuccessfully =
  "Notary request created successfully!";
exports.notaryRequestUpdatedSuccessfully =
  "Notary request updated successfully!";
exports.youCanCheckYourNotaryRequestStatusInNotariesSection =
  "You can check your notary request status in notaries section.";
exports.checkStatus = "Check Status";
exports.newNotaryEndPoint = "bx_block_menu_ordering/notary_requests";
exports.inviteClient = "Invite Client";
exports.noNotaryRequest = "No Notary Requests!";
exports.pleaseEnterRecipientName = "Please enter recipient name";
exports.pleaseEnterRecipientEmail = "Please enter recipient email";
exports.regexForNumber = /^(?!0)\d+$/;
exports.pleaseEnterTheVaildNumberOfDocumentNotarised =
  "Please enter the vaild Number of document notarised";
exports.invalidFileFormatChooseAnotherFile =
  "Invalid file format, Choose another file.";
exports.signatoryMustBeChecked = "Signatory must be checked";
exports.nameRegex = /^[A-Z a-z]+$/;
exports.fotterText = "renotary 2024";
exports.termsService = "Terms of service";
exports.privacyPoilcy = "Privacy policy";
exports.noNotaryRequestforNotaryUser =
  "Stay calm and relax. You'll receive your first notary request soon";

exports.noOngoingRequest = "No Ongoing Requests";
exports.noOngoingforNotaryUser =
  "Stay calm and relax. You'll receive your notary request soon";

exports.getCancellationChargesApiEndpointPart1 =
  "bx_block_menu_ordering/notary_requests/";
exports.getCancellationChargesApiEndpointPart2 = "/cancellation_charges";
exports.getCancellationChargesApiMethod = "GET";
exports.getCancellationChargesContentType = "application/json";

exports.cancelNotaryRequestApiEndpointPart1 =
  "bx_block_menu_ordering/notary_requests/";
exports.cancelNotaryRequestApiEndpointPart2 = "/cancel_end_user_notary_request";
exports.cancelNotaryRequestApiMethod = "PUT";
exports.cancelNotaryRequestContentType = "application/json";

exports.editNotaryRequestEndPoint = "bx_block_menu_ordering/notary_requests";
// Customizable Area End
exports.completeProfileText = "Complete your profile";
exports.completeProfileDescription = " By completing all the details you'll be able to receive the Notary request.";
exports.phoneNumberText = "Add Phone Number";
exports.chooseNotary = "Choose Notary Service";
exports.addCompleteAddress = "Add Complete Address";
exports.addQualifiedSignature = "Add Qualified Signature";
exports.addVAT = "Add VAT (Sales Tax)";
exports.addPaymentDetails = "Add Payment Details";
exports.emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.invalidRecipientEmail = "Please enter a valid email address";
// Customizable Area End
