export const allRequest = require("../assets/7c6a50fd4c9d945bbaa51400ea943f88.png");
export const progressRequest = require("../assets/88b2ca4dfa1729df3385f2021745bf78.png");
export const completeRequest = require("../assets/fe6f8ee1ec63fa32954ee537ff44333c.png");
export const bellIcon = require("../assets/bellICon.png");
export const groupImg = require('../assets/grouppage.png');
export const logoImg = require('../assets/logo.png');
export const logoBlueImg = require('../assets/logoBlue.png');
export const localProfile = require("../assets/acount.png");
export const saveImage = require("../assets/095be4a7d59419f3e29cdf3ae2cd8135.png");
export const cancelImage = require("../assets/5fa4e37863a6766904897d4629041c86.png");
export const service = require("../assets/service.png");
export const outgoing = require("../assets/outgoing.png");

