Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "calendar";
exports.labelBodyText = "calendar Body";

exports.btnExampleTitle = "CLICK ME";
exports.renotaryEventApiEndpoint = "bx_block_calendar/booked_slots"
exports.httpGetMethod = "GET";
exports.cancelText = "Close";
exports.guestText = "Guests";
exports.googleEventEntPoint ="https://www.googleapis.com/calendar/v3/calendars/primary/events";
exports.getOutlookEventEndpoint = "https://graph.microsoft.com/v1.0/me/events"
// Customizable Area End