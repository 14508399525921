import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

export  interface Item{
  title:string;
  image:string;
}

export interface FileData {
  name: string;
  type: string;
  uri: string | undefined;
}
export interface ApiCallInterface {
  contentType?:string;
  method?:string;
  endPoint?:string;
  body?:object;
  type?:string;
}

export interface ValidResponseType {
  message: object;
  data: object;
  errors:string;
}

export interface ResponseCountry  {
  id: string,
  type: string,
  attributes: {
      name: string
  }
}

export interface ProfileDetails {
  data: {
    attributes: {
      id: number,
      first_name: null,
      last_name: null,
      full_phone_number: string,
      city: string,
      post_code: string,
      country_code: string,
      phone_number: string,
      email: string,
      activated: true,
      status: string,
      gender: null,
      date_of_birth: null,
      age: null,
      country: string,
      address: string,
      address_line_2: string,
      contact_name: null,
      company_name: string,
      full_name: string;
      user_type: string;
      photo?: { url: string };
      role_id: number;
    }
}
}

export interface CountryData {
  countries: [
    {
      country_code: string,
      name: string
    }
  ]
}

export interface CountryName {
  data: [
    {
      id: string,
      type: string,
      attributes: {
          name: string
      }
  }
  ]
}

export interface CountryDataObject {
  country_code: string,
  name: string
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  services: Item[];
  supports: Item[];
  isSideBarOpen: boolean;
  isEditing: boolean;
  avatar?: {
    url?: string,
    file?:File | null;
  };
  fullName: string;
  emailProfile: string;
  companyName: string;
  countryCode: string;
  phoneNumberProfile: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  country: string;
  postalCode: string;
  countryCodes: Array<CountryDataObject>;
  profileOpen: boolean;
  isFullName: boolean;
  isCompany: boolean;
  isAddressOne: boolean;
  isAddressTwo: boolean;
  isPhone: boolean;
  isCity: boolean;
  isPostCode: boolean;
  invalidFile: boolean;
  online: boolean;
  roleID: number;
  user_type: string;
  countries: Array<ResponseCountry>;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getUserProfileApiCallID: string = "";
  editUserProfileApiCallID: string = "";
  getCountryCodeApiCallID: string = "";
  getCountryAPICallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
   
    this.state = {
      services: [
        {
          title: "Dashboard",
          image: require("../assets/dash.png")
        }, {
          title: "Requests",
          image: require("../assets/request.png")
        }, {
          title: "Catalogue",
          image: require("../assets/catalogue.png")
        }, {
          title: "Calendar",
          image: require("../assets/calender.png")
        }, {
          title: "My Account",
          image: require("../assets/acount.png")
        }

      ],
      supports: [
        {
          title: "Contact Us",
          image: require("../assets/contactus.png")
        }, {
          title: "Help",
          image: require("../assets/help.png")
        }, {
          title: "Log Out",
          image: require("../assets/logout.png")
        }
      ],
      isSideBarOpen: false,
      isEditing: false,
      avatar: {
        url: "" ,
        file: null
      },
      fullName: "",
      emailProfile: "",
      companyName:"",
      countryCode: "",
      phoneNumberProfile: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      country: "",
      postalCode: "",
      countryCodes: [],
      profileOpen: false,
      isFullName: false,
      isCompany:false,
      isAddressOne: false,
      isAddressTwo: false,
      isPhone: false,
      isCity: false,
      isPostCode: false,
      invalidFile: false,
      online: false,
      roleID: 0,
      countries: [],
      user_type:''
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(
          getName(MessageEnum.SessionResponseData)
      );
      this.setState({
        profileOpen:data.isEdit,
        isEditing:data.isEdit
      })
  }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(this.isValidResponse(webResponseJson)){
        this.responseSuccessCallBack(webApiRequestCallId,webResponseJson)
       }
    }
    // Customizable Area End
  }

   // Customizable Area Start

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  }
  responseSuccessCallBack = (apiRequestCallId: string, responseJson: ProfileDetails & CountryData & CountryName ) => {
    if (apiRequestCallId === this.getUserProfileApiCallID) {
      if (responseJson && responseJson.data)
      this.setState({ 
        fullName: responseJson.data.attributes.full_name,
        emailProfile: responseJson.data.attributes.email,
        addressOne: responseJson.data.attributes.address,
        addressTwo: responseJson.data.attributes.address_line_2,
        city: responseJson.data.attributes.city,
        country: responseJson.data.attributes.country,
        postalCode: responseJson.data.attributes.post_code,
        countryCode: responseJson.data.attributes.country_code,
        phoneNumberProfile: responseJson.data.attributes.phone_number?.toString(),
        avatar: responseJson.data.attributes.photo,
        roleID: responseJson.data.attributes.role_id,
        companyName: responseJson.data.attributes.company_name,
        user_type: responseJson.data.attributes.user_type
       });
    } if (apiRequestCallId === this.editUserProfileApiCallID) {
      this.setState({
        addressOne: responseJson.data.attributes.address,
        emailProfile: responseJson.data.attributes.email,
        fullName: responseJson.data.attributes.full_name,
        postalCode: responseJson.data.attributes.post_code,
        addressTwo: responseJson.data.attributes.address_line_2,
        city: responseJson.data.attributes.city,
        country: responseJson.data.attributes.country,
        avatar: responseJson.data.attributes.photo,
        countryCode: responseJson.data.attributes.country_code,
        phoneNumberProfile: responseJson.data.attributes.phone_number,
        isEditing:false
      });
    }  if (apiRequestCallId === this.getCountryCodeApiCallID) {
      this.setState({ countryCodes: responseJson.countries})
    } if (apiRequestCallId === this.getCountryAPICallID) {
      this.setState({ countries: responseJson.data })
    }
  }

  notaryNavigation = () => {
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
      msgs.addData(getName(MessageEnum.NavigationTargetMessage), "UserNotaryService");
      msgs.addData( getName(MessageEnum.NavigationPropsMessage), this.props );
      this.send(msgs);
      return true;
  }

  handleProfileOpen = () => {
    this.setState({ profileOpen : true});
  };

  handleChangeEditing = () => {
    this.setState({ isEditing: true });
  };

  handleCancel = () => {
    this.setState({ isEditing: false, isFullName: false, isAddressOne:false, isCity:false, isPostCode:false, isPhone: false,isCompany:false}, () => this.getProfile());
  };

  handleChangeSave = () => {
    const { fullName, city, postalCode, addressOne, phoneNumberProfile ,companyName} = this.state;
    const errors = {
      isFullName: !fullName || fullName.length === 0,
      isCity: !city || city === "" || city === "null",
      isAddressOne: !addressOne || addressOne === "" || addressOne === "null",
      isPhone: !phoneNumberProfile || phoneNumberProfile.length < 10,
      isPostCode: !postalCode || postalCode === "null" || postalCode.length === 0,
      isCompany: companyName === ""
    };
    this.setState(errors, () => {
      const noErrors = Object.values(errors).every(error => !error);
      if (noErrors) {
        this.editProfile();
      }
    });
  };
  
  handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event?.target?.files[0]) {
      const validTypes = ['image/png', 'image/jpeg', 'image/jpg']; 
      if (!validTypes.includes(event?.target?.files[0].type)) {
       this.setState({ invalidFile: true})
        return;
      }
      else{this.setState({invalidFile:false})}
      this.setState({
        avatar: {
          url: URL.createObjectURL(event.target.files[0]),
          file : event.target.files[0]
        }
      });
    }
  };

  setOnline = () => {
    this.setState({ online: !this.state.online})
  }

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      fullName: event.target.value, isFullName: false
    });
  };
  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      emailProfile: event.target.value,
    });
  };

  handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      companyName: event.target.value,
    });
  };

  handleCodeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    this.setState({ countryCode: event.target.value as string });
  };

  handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      phoneNumberProfile: event.target.value, isPhone: false
    });
  };

  handleAddressOneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      addressOne: event.target.value, isAddressOne: false
    });
  };
  handleAddressTwoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      addressTwo: event.target.value
    });
  };

  handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      city: event.target.value, isCity: false
    });
  };

  handleBackArrow = () => {
    this.setState({ profileOpen: false})
  }

  handleCountryChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode
  ) => {
    const { value } = event.target;
    this.setState({ country: value as string });
  };

  handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isPostCode: false,
      postalCode: event.target.value
    });
  };

  async componentDidMount() {
    super.componentDidMount();
    this.countryCodeAPI();
    this.getProfile();
    this.getCountryAPI();
  }

  apiCall = async (apiData: ApiCallInterface) => {
    let token = await getStorageData("token");
     const {method, endPoint, body, type } = apiData;
    const header = {
      token: token
    };
     const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
     requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
     requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         endPoint
     );
     requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         method
     );
    body && type !== "formData" ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      ) :
      requestMessage.addData(getName(
        MessageEnum.RestAPIRequestBodyMessage),
        body
      )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
};

  getProfile = async () => {
    this.getUserProfileApiCallID = await this.apiCall({
      method: configJSON.methodTypeApiGetUserProfile,
      endPoint: configJSON.getUserProfileEndPoint
    });
  };

  editProfile = async () => {
    let formData = new FormData();

    formData.append("profile[address]", this.state.addressOne );
    formData.append("profile[city]", this.state.city);
    formData.append("profile[address_line_2]", this.state.addressTwo);
    formData.append("profile[post_code]", this.state.postalCode);
    formData.append("profile[email]", this.state.emailProfile);
    formData.append("profile[full_name]", this.state.fullName);
    formData.append("profile[country]", this.state.country);
    this.state.avatar?.file !== undefined && 
    formData.append("profile[photo]",this.state.avatar?.file as File);
    formData.append("profile[full_phone_number]", this.state.countryCode + this.state.phoneNumberProfile)

    this.editUserProfileApiCallID = await this.apiCall({
      method: configJSON.apiUpdateUserType,
      endPoint: configJSON.editProfileAPIEndPoint,
      body: formData,
      type: "formData"
    });
  };

  countryCodeAPI = async() => {
    this.getCountryCodeApiCallID = await this.apiCall({
      method: configJSON.methodTypeApiGetUserProfile,
      endPoint: configJSON.countryCodeAPIEndPoint
    });
  };

  getCountryAPI = async () => {
    this.getCountryAPICallID = await this.apiCall({
      method: configJSON.methodTypeApiGetUserProfile,
      endPoint: configJSON.getCountryAPI
    })
  }

  handleSettingsBtn = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  getValueOrDefault = (value:string) => {
    return value === "null" ? "" : value;
  };

  getCountryCodeValue = (value:string) => {
    return value === null ? "44" : value;
  };

  goToTransactionHistory = () => {
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
      msgs.addData(getName(MessageEnum.NavigationTargetMessage), "TransactionHistory");
      msgs.addData( getName(MessageEnum.NavigationPropsMessage), this.props );
      this.send(msgs);
  }
  // Customizable Area End

}
