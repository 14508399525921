import React, { Component } from "react";
type MyProps = {
  testID: string;
  isChecked: boolean;
  onChangeValue?: (value: boolean) => void;
};
type MyState = { isChecked: boolean; onChangeValue?: (value: boolean) => void };

// Customizable Area Start
import {
  TextField,
  Box,
  Button,
  Grid, Typography, Link, styled,  
} from "@material-ui/core";
import { GBC, ICO, ISO, UKAS, appStore, builder, coming, cross, crown, download, facebook, fast, googlePlay, greenRating, insta, linkedin, logo1, rating, tictok, youtube, zoom } from "../src/assets";
import { NavLink, } from 'react-router-dom';
// Customizable Area End
// Customizable Area Start

// Customizable Area End
export class  Footer extends Component<MyProps, MyState> {

  constructor(props: any) {
    super(props);   
  }
 
    render(){
      const TextFieldArea= styled(TextField)({
        "& .MuiOutlinedInput-root": {
         height:"44px"
        },
      })
  return (
    // Customizable Area Start
    // Required for all blocks
    <Box style={webStyles.mainBox}>
      <DesktopFooterBox>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          style={{ columnGap: "60px" }}
          width={"100%"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"30%"}
            style={{ rowGap: "48px" }}
          >
            <NavLink to="/">
              <img src={logo1} alt="notary" width={"194px"} />
            </NavLink>          
            
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              style={{ rowGap: "16px" }}
              width={"100%"}
            >
              <Typography style={webStyles.accredit}>Accredited by</Typography>
              <Box display={"flex"} width={"100%"} style={{ columnGap: "4px" }}>
                <img src={ICO} alt="notary" width={"50px"} />
                <img src={ISO} alt="notary" width={"35px"} />
                <img src={crown} alt="notary" width={"22px"} />
                <img src={UKAS} alt="notary" width={"128px"} />
              </Box>
              <Box></Box>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection="column"
            width={"20%"}
            style={{ rowGap: "12px" }}
          >
            <Typography style={webStyles.heads}>Partners</Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              style={{ rowGap: "8px" }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                style={{ columnGap: "8px" }}
              >
                <img src={builder} alt="notary" />
               <Button href="https://www.builder.ai/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none",textDecorationLine:"none"}}>
                    Builder AI
                </Typography>
                  </Button>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                style={{ columnGap: "8px" }}
              >
                <img src={download} alt="notary" />
                <Button href="https://www.docusign.com/en-in/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none",textDecorationLine:"none"}}>
                Docusign
                </Typography>
                  </Button>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                style={{ columnGap: "8px" }}
              >
                <img src={zoom} alt="notary" />
                <Button href="https://zoom.us/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none",textDecorationLine:"none"}}>
                Zoom
                </Typography>
                  </Button>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                style={{ columnGap: "8px" }}
              >
                <img src={GBC} alt="notary" />
                <Button href="https://www.gbgplc.com/en/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none",textDecorationLine:"none"}}>
                GBG
                </Typography>
                  </Button>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                style={{ columnGap: "8px" }}
              >
                <img src={fast} alt="notary" />
                <Button href="https://www.fasttrackapostille.co.uk/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none",textDecorationLine:"none"}}>
                Fast Track Apostille
                </Typography>
                  </Button>
              </Box>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection="column"
            style={{ rowGap: "12px" }}
          >
            <Typography style={webStyles.heads}>Company</Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              style={{ rowGap: "8px" }}
            >
              <Typography style={{...webStyles.headsChild,textDecorationLine:"none"}}>
              <NavLink
                to="/Aboutus"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                style={webStyles.partnersLink}
              >
                About us
              </NavLink></Typography>
              <Typography style={{...webStyles.headsChild,textDecorationLine:"none"}}>
              <NavLink
                to="/Services"
                style={webStyles.partnersLink}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Services
              </NavLink></Typography>
              <Typography style={{...webStyles.headsChild,textDecorationLine:"none"}}>
              <NavLink
                to="/Faq"
                style={webStyles.partnersLink}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                FAQ
              </NavLink></Typography>
              <Typography style={{...webStyles.headsChild,textDecorationLine:"none"}}>
              <NavLink
                to="/Contactus"
                style={webStyles.partnersLink}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Contact us
              </NavLink></Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            style={{ rowGap: "32px" }}
            width={"30%"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"space-between"}
              style={{ rowGap: "24px" }}
              width={"100%"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                style={{ rowGap: "12px" }}
                width={"100%"}
              >
                <Typography style={webStyles.heads}>Newsletter</Typography>
                <Box
                  display={"flex"}
                  style={{ columnGap: "8px" }}
                  width={"100%"}
                >
                  <TextFieldArea
                   id="outlined-basic"
                   variant="outlined"
                   style={webStyles.input}
                   placeholder="Your E-mail Address" >
                  </TextFieldArea>

                  <Button
                    variant="contained"
                    style={{ ...webStyles.signup, textTransform: "none" }}
                  >
                    Subscribe
                  </Button>
                </Box>
              </Box>
              <Box
                display={"flex"}
                style={{ columnGap: "12px" }}
                width={"100%"}
              >
                <Button
                  style={{
                    width: "195px",
                    height: "61px",
                    padding: "10px 0",
                    backgroundColor: "#fff",
                  }}
                >
                  <img
                    src={greenRating}
                    alt="notary"
                    width="100%"
                    height="61px"
                    style={{ maxWidth: "195px" }}
                  />
                </Button>
                <Button
                  style={{
                    width: "195px",
                    height: "61px",
                    backgroundColor: "#fff",
                  }}
                >
                  <img
                    src={rating}
                    alt="notary"
                    width="100%"
                    height="41px"
                    style={{ maxWidth: "124px" }}
                  />
                </Button>
              </Box>
            </Box>
            <Box>
              <img src={cross} alt="notary" style={webStyles.socialCont} />
              <img src={facebook} alt="notary" style={webStyles.socialCont} />
              <img src={linkedin} alt="notary" style={webStyles.socialCont} />
              <img src={insta} alt="notary" style={webStyles.socialCont} />
              <img src={youtube} alt="notary" style={webStyles.socialCont} />
              <img src={tictok} alt="notary" style={webStyles.socialCont} />
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ columnGap: "48px" }}
          width={"100%"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            style={{ columnGap: "16px" }}
          >
            <img src={appStore} alt="notary" />
            <img src={googlePlay} alt="notary" />
          </Box>
          <Typography style={{ ...webStyles.footerreno }}>
            renotary is the leader in remote online notarisation, which is
            simpler, smarter and safer than notarising documents on paper.
            renotary™ Network is the world's largest network of on-demand
            notaries.
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          style={{ columnGap: "24px" }}
          width={"100%"}
        >
          <Typography style={webStyles.copyrightText}>
            © renotary 2024
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            style={{ columnGap: "24px" }}
          >
            <NavLink
              to="/Termsandconditions"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Typography style={webStyles.privacyPolicyTnCText}>
                Terms of Service
              </Typography>
            </NavLink>
            <NavLink
              to="/Privacypolicy"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Typography style={webStyles.privacyPolicyTnCText}>
                Privacy policy
              </Typography>
            </NavLink>
          </Box>
        </Box>
      </DesktopFooterBox>
      <TabletFooterBox>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"95%"}
          style={{ rowGap: "48px" }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            width={"100%"}
            style={{ columnGap: "24px" }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"292px"}
              style={{ rowGap: "48px" }}
            >
              <NavLink to="/">
              <img src={logo1} alt="notary" width={"194px"} />
            </NavLink>              
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                style={{ rowGap: "16px" }}
                width={"100%"}
              >
                <Typography style={webStyles.accredit}>
                  Accredited by
                </Typography>
                <Box
                  display={"flex"}
                  width={"100%"}
                  style={{ columnGap: "4px" }}
                >
                  <img src={ICO} alt="notary" width={"50px"} />
                  <img src={ISO} alt="notary" width={"35px"} />
                  <img src={crown} alt="notary" width={"22px"} />
                  <img src={UKAS} alt="notary" width={"128px"} />
                </Box>
              </Box>
            </Box>
            <Box
              width={"360px"}
              display={"flex"}
              justifyContent={"space-between"}
              style={{ columnGap: "24px" }}
            >
              <Box
                display={"flex"}
                flexDirection="column"
                width={"50%"}
                style={{ rowGap: "12px" }}
              >
                <Typography style={webStyles.heads}>Partners</Typography>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  style={{ rowGap: "8px" }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    style={{ columnGap: "8px" }}
                  >
                    <img src={builder} alt="notary" />
                    <Button href="https://www.builder.ai/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none"}}>
                    Builder AI
                </Typography>
                  </Button>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    style={{ columnGap: "8px" }}
                  >
                    <img src={download} alt="notary" />
                    <Button href="https://www.docusign.com/en-in/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none"}}>
                Docusign
                </Typography>
                  </Button>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    style={{ columnGap: "8px" }}
                  >
                    <img src={zoom} alt="notary" />
                    <Button href="https://zoom.us/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none"}}>
                Zoom
                </Typography>
                  </Button>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    style={{ columnGap: "8px" }}
                  >
                    <img src={GBC} alt="notary" />
                    <Button href="https://www.gbgplc.com/en/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none"}}>
                GBG
                </Typography>
                  </Button>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    style={{ columnGap: "8px" }}
                  >
                    <img src={fast} alt="notary" />
                    <Button href="https://www.fasttrackapostille.co.uk/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none"}}>
                Fast Track Apostille
                </Typography>
                  </Button>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                flexDirection="column"
                width={"50%"}
                style={{ rowGap: "12px" }}
              >
                <Typography style={webStyles.heads}>Company</Typography>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  style={{ rowGap: "8px" }}
                >
                  <NavLink
                    to="/Aboutus"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    style={webStyles.headsChild}
                  >
                    About us
                  </NavLink>
                  <NavLink
                    to="/Services"
                    style={webStyles.headsChild}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Services
                  </NavLink>
                  <NavLink
                    to="/Faq"
                    style={webStyles.headsChild}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    FAQ
                  </NavLink>
                  <NavLink
                    to="/Contactus"
                    style={webStyles.headsChild}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Contact us
                  </NavLink>
                </Box>
              </Box>
            </Box>
          </Box>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <TabletNewsletterGrid item sm={12} md={6} lg={6}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ rowGap: "24px" }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  style={{ rowGap: "12px" }}
                  width={"402px"}
                >
                  <Typography style={webStyles.heads}>Newsletter</Typography>
                  <Box display={"flex"} style={{ columnGap: "8px" }}>
                    <TextFieldArea
                      id="outlined-basic"
                      variant="outlined"
                      style={webStyles.input}
                      placeholder="Your E-mail Address" >
                    </TextFieldArea>
                    <Button
                      variant="contained"
                      style={{ ...webStyles.signup, textTransform: "none" }}
                    >
                      Subscribe
                    </Button>
                  </Box>
                </Box>
                <Box display={"flex"} style={{ columnGap: "12px" }}>
                  <Button
                    style={{
                      height: "61px",
                      padding: "10px 0",
                      width: "195px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <img
                      src={greenRating}
                      alt="notary"
                      width="100%"
                      height="61px"
                      style={{ maxWidth: "195px" }}
                    />
                  </Button>
                  <Button
                    style={{
                      width: "195px",
                      height: "61px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <img
                      src={rating}
                      alt="notary"
                      width="100%"
                      height="41px"
                      style={{ maxWidth: "124px" }}
                    />
                  </Button>
                </Box>
              </Box>
            </TabletNewsletterGrid>
            <TabletSocialMediaGrid item sm={12} md={6} lg={6}>
              <TabletSocialMediaBox
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{ rowGap: "48px" }}
              >
                <Box>
                  <img src={cross} alt="notary" style={webStyles.socialCont} />
                  <img
                    src={facebook}
                    alt="notary"
                    style={webStyles.socialCont}
                  />
                  <img
                    src={linkedin}
                    alt="notary"
                    style={webStyles.socialCont}
                  />
                  <img src={insta} alt="notary" style={webStyles.socialCont} />
                  <img
                    src={youtube}
                    alt="notary"
                    style={webStyles.socialCont}
                  />
                  <img src={tictok} alt="notary" style={webStyles.socialCont} />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  style={{ columnGap: "16px" }}
                >
                  <img src={appStore} alt="notary" />
                  <img src={googlePlay} alt="notary" />
                </Box>
              </TabletSocialMediaBox>
            </TabletSocialMediaGrid>
          </Grid>
          <Box display={"flex"} justifyContent={"center"}>
            <Typography
              align="center"
              style={{ ...webStyles.footerreno, width: "738px" }}
            >
              renotary is the leader in remote online notarisation, which is
              simpler, smarter and safer than notarising documents on paper.
              renotary™ Network is the world's largest network of on-demand
              notaries.
            </Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          style={{ columnGap: "24px" }}
          width={"95%"}
        >
          <Typography style={webStyles.copyrightText}>
            © renotary 2024
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            style={{ columnGap: "24px" }}
          >
            <NavLink
              to="/Termsandconditions"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Typography style={webStyles.privacyPolicyTnCText}>
                Terms of Service
              </Typography>
            </NavLink>
            <NavLink
              to="/Privacypolicy"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Typography style={webStyles.privacyPolicyTnCText}>
                Privacy policy
              </Typography>
            </NavLink>
          </Box>
        </Box>
      </TabletFooterBox>
      <MobileFooterBox>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          style={{ rowGap: "48px" }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            style={{ rowGap: "48px" }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{ rowGap: "48px" }}
            >
              <NavLink to="/">
              <img src={logo1} alt="notary" width={"194px"} />
            </NavLink>              
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{ rowGap: "16px" }}
              >
                <Typography style={webStyles.accredit}>
                  Accredited by
                </Typography>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={{ columnGap: "12px" }}
                >
                  <img src={ICO} alt="notary" />
                  <img src={ISO} alt="notary" />
                  <img src={crown} alt="notary" />
                  <img src={UKAS} alt="notary" />
                </Box>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
              style={{ rowGap: "48px" }}
            >
              <Box
                width={"67%"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Box
                  display={"flex"}
                  flexDirection="column"
                  style={{ rowGap: "12px" }}
                >
                  <Typography style={webStyles.heads}>Partners</Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    style={{ rowGap: "8px" }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      style={{ columnGap: "8px" }}
                    >
                      <img src={builder} alt="notary" />
                      <Button href="https://www.builder.ai/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none"}}>
                    Builder AI
                </Typography>
                  </Button>
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      style={{ columnGap: "8px" }}
                    >
                      <img src={download} alt="notary" />
                      <Button href="https://www.docusign.com/en-in/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography align="left" style={{...webStyles.headsChild, textTransform: "none"}}>
                Docusign
                </Typography>
                  </Button>
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      style={{ columnGap: "8px" }}
                    >
                      <img src={zoom} alt="notary" />
                      <Button href="https://zoom.us/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none"}}>
                Zoom
                </Typography>
                  </Button>
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      style={{ columnGap: "8px" }}
                    >
                      <img src={GBC} alt="notary" />
                      <Button href="https://www.gbgplc.com/en/" style={webStyles.partnersLink} variant="text" target="_blank">
                <Typography style={{...webStyles.headsChild, textTransform: "none"}}>
                GBG
                </Typography>
                  </Button>       
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      style={{ columnGap: "8px" }}
                    >
                      <img src={fast} alt="notary" />
                      <Button href="https://www.fasttrackapostille.co.uk/" style={webStyles.partnersLink} variant="text" target="_blank">
                        <Typography style={{...webStyles.headsChild, textTransform: "none"}}>
                          Fast Track Apostille
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection="column"
                  style={{ rowGap: "12px" }}
                >
                  <Typography style={webStyles.heads}>Company</Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    style={{ rowGap: "8px" }}
                  >
                    <NavLink
                      to="/Aboutus"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      style={webStyles.headsChild}
                    >
                      About us
                    </NavLink>
                    
                    <NavLink
                      to="/Services"
                      style={webStyles.headsChild}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      Services
                    </NavLink>
                    <NavLink
                      to="/Faq"
                      style={webStyles.headsChild}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      FAQ
                    </NavLink>
                    <NavLink
                      to="/Contactus"
                      style={webStyles.headsChild}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      Contact us
                    </NavLink>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ rowGap: "32px" }}
                width={"100%"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  style={{ rowGap: "24px" }}
                  width={"67%"}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    style={{ rowGap: "12px" }}
                    width={"100%"}
                  >
                    <Typography style={webStyles.heads}>Newsletter</Typography>
                    <Box
                      display={"flex"}
                      style={{ columnGap: "8px" }}
                      width={"100%"}
                    >                    
                       <TextFieldArea
                         id="outlined-basic"
                         variant="outlined"
                         style={webStyles.input}
                         placeholder="Your E-mail Address" >
                       </TextFieldArea>
                      <Button
                        variant="contained"
                        style={{ ...webStyles.signup, textTransform: "none" }}
                      >
                        Subscribe
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    style={{ columnGap: "12px" }}
                    width={"100%"}
                  >
                    <Button
                      style={{
                        width: "50%",
                        height: "61px",
                        padding: "10px 0",
                        backgroundColor: "#fff",
                      }}
                    >
                      <img
                        src={greenRating}
                        alt="notary"
                        width="100%"
                        height="61px"
                        style={{ maxWidth: "195px" }}
                      />
                    </Button>
                    <Button
                      style={{
                        width: "50%",
                        height: "61px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <img
                        src={rating}
                        alt="notary"
                        width="100%"
                        height="41px"
                        style={{ maxWidth: "124px" }}
                      />
                    </Button>
                  </Box>
                </Box>
                <Box>
                  <img src={cross} alt="notary" style={webStyles.socialCont} />
                  <img
                    src={facebook}
                    alt="notary"
                    style={webStyles.socialCont}
                  />
                  <img
                    src={linkedin}
                    alt="notary"
                    style={webStyles.socialCont}
                  />
                  <img src={insta} alt="notary" style={webStyles.socialCont} />
                  <img
                    src={youtube}
                    alt="notary"
                    style={webStyles.socialCont}
                  />
                  <img src={tictok} alt="notary" style={webStyles.socialCont} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            style={{ rowGap: "24px" }}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              style={{ columnGap: "16px" }}
            >
              <img src={appStore} alt="notary" />
              <img src={googlePlay} alt="notary" />
            </Box>
            <Typography align="center" style={{ ...webStyles.footerreno }}>
              renotary is the leader in remote online notarisation, which is
              simpler, smarter and safer than notarising documents on paper.
              renotary™ Network is the world's largest network of on-demand
              notaries.
            </Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          style={{ columnGap: "24px" }}
        >
          <Typography style={webStyles.copyrightText}>
            © renotary 2024
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            style={{ columnGap: "24px" }}
          >
            <NavLink
              to="/Termsandconditions"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Typography style={webStyles.privacyPolicyTnCText}>
                Terms of Service
              </Typography>
            </NavLink>
            <NavLink
              to="/Privacypolicy"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Typography style={webStyles.privacyPolicyTnCText}>
                Privacy policy
              </Typography>
            </NavLink>
          </Box>
        </Box>
      </MobileFooterBox>
    </Box>
    // Customizable Area End
  );
    }
}




// Customizable Area Start
const DesktopFooterBox = styled(Box)({
  "@media (max-width: 1152px)": {
    display: "none"
  },
  padding: "80px 80px 40px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: "60px"
})

const TabletFooterBox = styled(Box)({
  "@media (max-width: 600px) or (min-width: 1153px)": {
    display: "none"
  },
  padding: "80px 24px 40px",
  display: "flex",
  flexDirection: "column",
  alignItems:"center",
  gap: "60px"
})

const MobileFooterBox = styled(Box)({
  "@media (min-width: 601px)": {
    display: "none"
  },
  padding: "80px 24px 40px",
  display: "flex",
  flexDirection: "column",
  gap: "60px",
})

const TabletNewsletterGrid = styled(Grid)({
  "@media (min-width:959px)": {
    display: "flex",
    justifyContent: "end",   
  },
})

const TabletSocialMediaGrid = styled(Grid)({
  "@media (min-width: 959px)": {
    display: "flex",
    justifyContent: "start",
  },
})

const TabletSocialMediaBox = styled(Box)({
  "@media (min-width: 959px)": {
    width: "402px",
    height: "167px"
  },
})

const webStyles = {
  mainBox: {
    backgroundColor: "#011342", 
    color: "#FFF",
    marginTop: "44px"
  },
  copyrightText: {
    color: "#CBD5E1",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "INTER"
  },
  privacyPolicyTnCText: {
    color: "#CBD5E1",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16.94px",
    textDecoration: "underline",
    fontFamily: "INTER"
  },
  footerreno: {
    color: "#CBD5E1",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "INTER",
  },

  input: {
    background: "#FFFFFF",
    borderRadius: "8px",
    width: "262px",
    height: "44px"
  },
  signup: {
    backgroundColor: "#012275",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "24px",
    borderRadius: "8px",
    padding: "12px 16px",
    fontFamily: "INTER",
    width: "100%",
    maxWidth: "132px",
    height: "44px"
  },
  socialCont: {
    padding: "12px"
  },
  heads: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "26px",
    fontFamily: "INTER"
  },
  headsChild: {
    color: "#FFFFFF",
    fontSize: 14,
    fontFamily: "INTER",
    textDecoration:"underline",
    fontWeight: 500,
    
  },
  partnersLink:{   
    color: "#FFFFFF",
    fontWeight:500,
    textDecoration:"none",
    fontSize:14,
    fontFamily:'Inter',
    lineHeight:'16px',
    padding: "0px",
    display: "flex",
    justifyContent: "start"
  },
  accredit: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFF",
    fontFamily: "INTER",
  }
};
// Customizable Area End