import React from "react";

// Customizable Area Start
import { Box, Typography } from '@material-ui/core';
import { styled } from "@material-ui/core/styles";

import { logoImg } from "./assets";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";
import MiniDrawer from "../../../components/src/MiniDrawer";
export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      <SideBox>
       <Box className="logoBox" data-test-id="landing-redirect" onClick={() => this.landingRedirect()}>
         <img src={logoImg} alt="" width={"100%"} height="100%" />
       </Box>
       <Box className="serviceSection">
           {this.state.services?.map((service, index) => (<Box key={index} data-testID={`naviButton${index}`} className="services" onClick={() => this.handleSideBarNavigation(service?.title)}>
             <img src={service.image} />
             <Typography>{service.title}</Typography>
           </Box>))}
       </Box>
       <Box className="supportSection">
           {this.state.supports?.map((support, index) => (<Box key={index} data-test-id="NevigationLinks" data-testID={`navigationBtn${index}`} className="services" onClick={() => this.logOutNvigation(support.title)} >
             <img src={support.image} />
             <Typography>{support.title}</Typography>
           </Box>))}
       </Box>
     </SideBox>
     
     </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const SideBox = styled(Box)({
  padding: "44px 25px",
  boxSizing: "border-box",
  display: "flex",
  postion: "fixed",
  height: "100vh",
  align: "center",
  gap: "50px",
  flexDirection: "column",
  width: "200px",
  backgroundColor: "#012275",
  borderRadius: "0px 24px 24px 0px",
  "& .logoBox": {
    width: "160px",
    height: "50px"
  },
  "& .serviceSection": {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      fontFamily: "Inter",
      color: "white"
    },
  },
  "& .supportSection": {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      fontFamily: "Inter",
      color: "white",
    },
  },
  "& .services": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer"
  }
});
// Customizable Area End
