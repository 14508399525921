import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { gapi, loadAuth2, loadGapiInsideDOM } from "gapi-script";
import { Message } from "../../../framework/src/Message";
import { OutlookAuthProvider } from "../../../components/src/OutlookAuthProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean;
    handleGoogleUi: boolean
    handleOutlookUi: boolean
    googleSynced: boolean;
    outlookSynced: boolean;
    googleAuthToken: string;
    googleAuthDetails: any;
    outlookAuthToken: string;
    outlookAuthDetails: any;
    outlookUserProfile: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CalendarSyncController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getGoogleAuthUserInfoApiCallId: string = "";
    getOutLookInfoApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loader: false,
            handleGoogleUi: false,
            handleOutlookUi: false,
            googleSynced: false,
            outlookSynced: false,
            googleAuthDetails: null,
            googleAuthToken: "",
            outlookAuthDetails: null,
            outlookAuthToken: "",
            outlookUserProfile: null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let webResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (webApiRequestCallId === this.getGoogleAuthUserInfoApiCallId) {
                this.handleGoogleAuthUserDetailsApiRes(webResponseJson)
            }

            if (webApiRequestCallId === this.getOutLookInfoApiCallId) {
                this.handleOutLookDetailsApiRes(webResponseJson)
            }
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        try {
            await loadGapiInsideDOM();

            loadAuth2(
                gapi,
                configJSON.googleCalendarClientId,
                configJSON.googleCalendarScope
            );
        } catch (error) { }

        const googleAuth = await getStorageData("google_auth");
        const outLookToken = await getStorageData("ms_accessToken")
        this.setGoogleAuth(googleAuth);
        this.setOutlookAuth(outLookToken)
    }

    setGoogleAuth = (token: string) => {
        if (token) {
            this.setState({ googleAuthToken: token });
            this.getGoogleUserInfoDetails(token);
        }
    }

    setOutlookAuth = async(token: string) => {
        if (token) {
            this.setState({ outlookAuthToken: token });
            this.getOutlookUserInfoDetails(token);
            const profilePic = await OutlookAuthProvider.fetchUserProfilePicture(token);
            if(profilePic){
                this.setState({outlookUserProfile: profilePic});
            }
        }
    }

    handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        this.setState({ ...this.state, [name]: checked });
    }

    handleGoogleAuthenticate = async () => {
        try {
            const auth2 = window.gapi.auth2.getAuthInstance();
            const response = await auth2.signIn();
            const user = response.getAuthResponse(true);
            this.setState({ googleAuthToken: user.access_token });
            setStorageData("google_auth", user.access_token);
            this.getGoogleUserInfoDetails(user.access_token);
        } catch (error) { }
    };

    getGoogleUserInfoDetails = async (token: string) => {
        this.setState({ loader: true })
        const header = {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getGoogleAuthUserInfoApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.googleAuthUserEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleGoogleAuthUserDetailsApiRes = (response: any) => {
        this.setState({ loader: false });
        if (!response.error) {
            this.setState({ googleAuthDetails: response, googleSynced: true, handleGoogleUi: true });
        } else {
            this.setState({ googleAuthDetails: null });
        }
    }

    handleRemoveGoogleAccount = async () => {
        try {
            const auth2 = window.gapi.auth2.getAuthInstance();
            if (auth2.isSignedIn.get()) {
                const currentUser = auth2.currentUser.get();
                currentUser.disconnect();
                await auth2.signOut();
            }
            removeStorageData("google_auth");

            this.setState({ googleAuthToken: "", googleAuthDetails: null, handleGoogleUi: false, });

        } catch (error) {
            console.error("google_auth_signout_error", "Error removing account:", error);
        }
    }

    handleSyncChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        this.setState({ ...this.state, [name]: checked });
        if (name === "googleSynced" && !checked) {
            removeStorageData("google_auth");
        } else if (name === "googleSynced" && checked) {
            this.handleGoogleAuthenticate();
        }else if (name === "outlookSynced" && !checked) {
            removeStorageData("ms_accessToken");
        }else if (name === "outlookSynced" && checked) {
            this.handleOutlookAuth();
        }
    };

    handleOutlookAuth = async () => {
        const token = await OutlookAuthProvider.getAccessToken();
        setStorageData("ms_accessToken", token);
        this.getOutlookUserInfoDetails(token);
        const outlookUserPic = await OutlookAuthProvider.fetchUserProfilePicture(token);
        if(outlookUserPic){
            this.setState({outlookUserProfile: outlookUserPic});
        }
    }

    getOutlookUserInfoDetails = async (token: string) => {
        this.setState({ loader: true })
        const header = {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getOutLookInfoApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.outlookAuthUserInfoEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleOutLookDetailsApiRes = (response: any) => {
        this.setState({ loader: false });
        if (!response.error) {
            this.setState({ outlookAuthDetails: response, handleOutlookUi: true, outlookSynced: true });
        } else {
            this.setState({ outlookAuthDetails: null, });
        }
    }

    handleRemoveOutLookAccount = async () => {
        const result = await OutlookAuthProvider.signOut();
        if (result) {
            this.setState({ handleOutlookUi: false, outlookSynced: false, outlookAuthDetails: null, outlookAuthToken: "" });
        }
    }

    // Customizable Area End
}