import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    enablePasswordField: boolean
    password: string;
    reTypePassword: string;
    handlePopup: boolean;
    handleSuccessPopup: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DeletePasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            enablePasswordField: false,
            password: '',
            reTypePassword: '',
            handlePopup: false,
            handleSuccessPopup: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    handlePasswordChange = (event: any) => {
        const { value } = event.target;

        const lastLetter = value.charAt(value.length - 1);
        const firstLetter = value.charAt(0);
        if (firstLetter != "*") {
            this.setState((prevState) => ({
                ...prevState,
                password: value,
                reTypePassword: value
            }));
        } else if (lastLetter != "*") {
            this.setState((prevState) => ({
                ...prevState,
                reTypePassword: this.state.reTypePassword + lastLetter,
                password: this.state.reTypePassword + lastLetter
            }));
        } else {
            let input = this.state.reTypePassword.slice(0, -1);
            this.setState((prevState) => ({
                ...prevState,
                password: input, reTypePassword: input
            }));

        }


    }

    handleClickShowPassword = () => {
        this.setState({
            enablePasswordField: !this.state.enablePasswordField,
        });
    };

    handleClickDeletePassword = () => {
        this.setState({
            handlePopup: !this.state.handlePopup,
        });
    }

    handleDeleteAccount = () => {

        this.setState({
            handlePopup: false,
            handleSuccessPopup: true
        })
        setTimeout(() => (
            this.setState({
                handleSuccessPopup: false
            })
        ), 2000)

    }
}



// Customizable Area End