import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { EventContentArg } from "@fullcalendar/react";
import { getStorageData } from "../../../framework/src/Utilities";


interface EventItem {
  id?: string;
  title: string;
  description?: string;
  start?: { dateTime: string; date: string };
  end?: { dateTime: string; date: string };
  conferenceData?: {
    entryPoints: { uri: string }[];
  };
  attendees?: {
    responseStatus: string;
    email: string;
  }[];
  summary: string;
}

interface OutlookEventItem {
  id?: string;
  subject: string;
  title: string;
  onlineMeetingUrl: string;
  bodyPreview?: string;
  start?: { dateTime: string; date: string };
  end?: { dateTime: string; date: string };
  conferenceData?: {
    entryPoints: { uri: string }[];
  };
  attendees?: {
    responseStatus: string;
    email: string;
  }[];
}

interface CalendarData extends Omit<EventItem, 'start' | 'end'> {
  start?: any;
  end?: any;
}
interface OutlookCalendarData extends Omit<OutlookEventItem, 'start' | 'end'> {
  start?: any;
  end?: any;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start 
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  date: any;
  selectedEventTab: number
  loading: boolean
  renotaryEvents: CalendarData[]
  selectedCalendarEvent: EventContentArg | null;
  token: string;
  googleCalendarData: CalendarData[];
  outlookCalendarData: OutlookCalendarData[];
  loader: boolean;
  google_auth: string,
  googleAuthDetails: boolean;
  outlook_auth: string,
  outlookAuthDetails: boolean;
  isMeetingConflicting: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CalendarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  renotaryCalendarEventCallId: string = "";
  getGoogleEventCallId: string = "";
  getOutlookEventCallId: string = "";

  mockEvents = [
    {
      "id": "event004",
      "title": "Sprint Retrospective",
      "description": "Reflect on the sprint and discuss improvements.",
      "start": {
        "dateTime": "2024-09-02T16:00:00Z",
        "date": "2024-09-02"
      },
      "end": {
        "dateTime": "2024-09-02T17:00:00Z",
        "date": "2024-09-02"
      },
      "conferenceData": {
        "entryPoints": [
          {
            "uri": "https://meet.example.com/retro004"
          }
        ]
      },
      "attendees": [
        {
          "responseStatus": "tentative",
          "email": "george.clark@example.com"
        },
        {
          "responseStatus": "accepted",
          "email": "hannah.davis@example.com"
        }
      ],
      "summary": "Sprint Retrospective"
    },
  ]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      date: new Date(),
      selectedEventTab: 0,
      loading: false,
      renotaryEvents: [],
      selectedCalendarEvent: null,
      token: "",
      googleCalendarData: [],
      outlookCalendarData: [],
      loader: false,
      google_auth: "",
      googleAuthDetails: false,
      outlook_auth: "",
      outlookAuthDetails: false,
      isMeetingConflicting: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.renotaryCalendarEventCallId) {
        this.handleRenotaryCalendarEvents(response)
      }
      if (apiRequestCallId === this.getOutlookEventCallId) {
        this.handleOutlookEventRes(response);
      }
      if (apiRequestCallId === this.getGoogleEventCallId) {
        this.handleGoogleEventsRes(response);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("authToken");
    const ms_accessToken = await getStorageData("ms_accessToken");
    const google_auth = await getStorageData("google_auth");
    this.setToken(token);
    this.setGoogleAuth(google_auth);
    this.setOutlookAuth(ms_accessToken);
    this.getRenotaryCalendarEvent();
    this.getGoogleEvents(google_auth);
    this.getOutlookEvents(ms_accessToken)
    // Customizable Area End
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>,) {
    if(prevState.date !== this.state.date){
      const google_auth = await getStorageData("google_auth");
      const outlook_auth = await getStorageData("ms_accessToken");
      this.setGoogleAuth(google_auth);
      this.setOutlookAuth(outlook_auth);
      this.getOutlookEvents(this.state.outlook_auth);
      this.checkMeetingsConflit();
    }
    if(prevState.googleCalendarData !== this.state.googleCalendarData || prevState.outlookCalendarData !== this.state.outlookCalendarData){
      this.checkMeetingsConflit();
    }
  }

  setGoogleAuth = (token: string) => {
    if(token){
      this.setState({google_auth: token,})
      this.getGoogleEvents(token);

    }
  }

  setOutlookAuth = (token: string) => {
    if(token){
      this.setState({outlook_auth: token,})
    }
  }

  setToken = (token: string) => {
    if(token){
      this.setState({token})
    }
  }

  handleEventsMock = () => {
    const events: EventItem[] = this.mockEvents;

    this.setState({
      renotaryEvents: events
        .filter((v) => !!v.start)
        .map((v) => ({
          ...v,
          title: v.summary,
          description: v.description,
          start: new Date(v.start!.dateTime),
          end: v.end ? new Date(v.end.dateTime) : undefined,
        })),
    });
  }
  onChange = (date: any) => {
    this.setState({ date });
  };

  handleEventTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ selectedEventTab: newValue });
  };

  a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  getRenotaryCalendarEvent = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.ApiContentType,
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.renotaryCalendarEventCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.renotaryEventApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleRenotaryCalendarEvents = (response: any) => {
    this.setState({ loading: false });
    if(response){
      this.handleEventsMock();
    }
  }

  renderFilteredEvents = () => {
    const { renotaryEvents, date } = this.state;

    const filteredEvents = renotaryEvents?.filter(
      event => new Date(event?.start).toDateString() === date.toDateString()
    );
    return filteredEvents;
  }

  setEventInfo = (eventInfo: any) => {
    this.setState({ selectedCalendarEvent: eventInfo });
  };

  closeModalHandle = () => {
    this.setState({ selectedCalendarEvent: null });
  };

  getDate = (date1: string | Date, date2: string | Date) => {
    let stDate = new Date(date1);
    let edDate = new Date(date2);

    if (Math.abs(stDate.getTime() - edDate.getTime()) / 36e5 === 24) {
      return stDate.toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        weekday: "long",
      });
    }

    let startDate = stDate.toLocaleString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      weekday: "long",
      hour: "2-digit",
      hour12: true,
      minute: "2-digit",
    });

    let endDate = edDate.toLocaleString(undefined, {
      hour: "2-digit",
      hour12: true,
      minute: "2-digit",
    });

    return startDate + " - " + endDate;
  };

  getGoogleEvents = (token: string) => {
    this.setState({loader: true, googleAuthDetails: true});
    const startOfDay = new Date(this.state.date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(this.state.date);
    endOfDay.setHours(23, 59, 59, 999);
    const header = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const getEventMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGoogleEventCallId = getEventMessage.messageId;

    getEventMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.googleEventEntPoint}?timeMin=${startOfDay.toISOString()}&timeMax=${endOfDay.toISOString()}&singleEvents=true`
    );

    getEventMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getEventMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getEventMessage.id, getEventMessage);
    return true;
  };

  getOutlookEvents = (token: string) => {
    if (token) {
      this.setState({ loader: true, outlookAuthDetails: true });
      const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const getEventMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getOutlookEventCallId = getEventMessage.messageId;

      getEventMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getOutlookEventEndpoint
      );

      getEventMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      getEventMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(getEventMessage.id, getEventMessage);
      return true;
    }
  };

  handleOutlookEventRes = (responseJson: any) => {
    if (!responseJson.error) {
      const events: OutlookEventItem[] = responseJson.value;

      this.setState({
        outlookCalendarData: events
          .filter((v) => !!v.start)
          .map((v) => ({
            ...v,
            title: v.subject,
            description: v.bodyPreview,
            start: new Date(v.start!.dateTime+"Z"),
            end: v.end ? new Date(v.end.dateTime+"Z") : undefined,
            conferenceData: v.onlineMeetingUrl
              ? { entryPoints: [{ uri: v.onlineMeetingUrl }] }
              : undefined,
            attendees: v.attendees?.map((attendee: any) => ({
              responseStatus: attendee.status?.response,
              email: attendee.emailAddress?.address,
            })),
          })),
        loader: false
      });
    } else {
      this.setState({ outlookAuthDetails: false, loader: false });
    }
  }

  handleGoogleEventsRes = (responseJson: any) => {
    this.setState({loader: false});
    if(!responseJson.error){
      const events: EventItem[] = responseJson.items;
      this.setState({
        googleCalendarData: events
          .filter((v) => !!v.start)
          .map((v) => ({
            ...v,
            title: v.summary,
            description: v.description,
            start: new Date(v.start!.dateTime),
            end: v.end ? new Date(v.end.dateTime) : undefined,
          })),
          loader: false
      });
    }else if(responseJson.error?.status === "UNAUTHENTICATED"){
      this.setState({
        loader: false,
        googleAuthDetails: false
      })
    }
    
  }

  renderFilteredGoogleEvents = () => {
    const {googleCalendarData} = this.state
    const filteredEvents = googleCalendarData?.filter(
      event => new Date(event?.start).toDateString() === this.state.date.toDateString()
    );
  
    return filteredEvents;
  }

  renderFilteredOutlookEvents = () => {
    const {outlookCalendarData} = this.state
    const filteredEvents = outlookCalendarData?.filter(
      event => new Date(event?.start).toDateString() === this.state.date.toDateString()
    );
    return filteredEvents;
  }

  renderFilteredAllEvents = () => {
    const {outlookCalendarData, googleCalendarData, renotaryEvents} = this.state;
    const allEventsData = [...outlookCalendarData, ...googleCalendarData, ...renotaryEvents];
    const filteredEvents = allEventsData?.filter(
      event => new Date(event?.start).toDateString() === this.state.date.toDateString()
    );
    return filteredEvents;
  }

  checkMeetingsConflit = () => {

    const {outlookCalendarData, googleCalendarData, renotaryEvents} = this.state;
    const allEventsData = [...outlookCalendarData, ...googleCalendarData, ...renotaryEvents];
    const filteredEvents = allEventsData?.filter(
      event => new Date(event?.start).toDateString() === this.state.date.toDateString()
    );

    for (let i = 0; i < filteredEvents.length; i++) {
      const event1Start = filteredEvents[i].start.getTime();
      const event1End = filteredEvents[i].end.getTime();
  
      for (let j = i + 1; j < filteredEvents.length; j++) {
        const event2Start = filteredEvents[j].start.getTime();
        const event2End = filteredEvents[j].end.getTime();

        if (event1Start < event2End && event1End > event2Start) {
          this.setState({isMeetingConflicting: true});
        }
      }
    }
  }

  handleClose = () => {
    this.setState({isMeetingConflicting: false});
  }

  showDate = () => {
    return this.state.date &&
      (new Date().toLocaleDateString("en-GB") === this.state.date.toLocaleDateString("en-GB")
        ? `Today, ${this.state.date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })}`
        : this.state.date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })
      )
  }

  // Customizable Area End
}
